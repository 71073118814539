import { useEffect, useRef } from 'react';
import {
  InfiniteData,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import apiQueryClient from 'utils/apiQueryClient';

/**
 * Defines the result type of this hook
 * The QueryResult generic describes a page of data
 */
export type InfiniteScrollResult<QueryResult> = {
  /**
   * An array of all the pages of data fetched so far
   */
  data: QueryResult[];
  error: unknown;
  isFetching: boolean;
  isEmpty: boolean;
  hasNextPage?: boolean;
  isFirstLoading: boolean;
  /**
   * A function to revalidate all the pages fetched so far
   */
  refetch: () => void;
  /**
   * A function to update the local data
   * Is used to update the local data after making a mutation (POST/PATCH/PUT) to reflect the changes in the UI
   * @param newData Either the mutated array of pages of data,
   * or a callback that gets the current data and returns the mutated array of pages
   */
  mutate: (newData: QueryResult[] | MutateCallback<QueryResult>) => void;
};

/**
 * Defines a function to mutate the data from a infinite query based on the current data
 */
export type MutateCallback<QueryResult> = (
  prevData: QueryResult[],
) => QueryResult[];

interface Params<T> {
  /**
   * The URL to get the first page of data (aka the url without any offset query param)
   */
  firstPageUrl: string;
  /**
   * Either a string or an array of values that uniquely identifies this query
   * This is used for caching the data
   * It should hold part of the url we are fetching to identify that specific GET (ex. `feed` for /feed) and any variables that are used in `getKey`
   */
  queryKey: QueryKey;
  /**
   * A function that should return the url for the next page of data
   * @param index The index of the page we have to fetch
   * @param lastPage The last page of data fetched if the url of the next pages depends of data previously fetched
   */
  getKey: (index: number, lastPage: T) => string | undefined;
  /**
   * DOM nodes that should trigger the fetching of the next page of data when they come into the viewport
   */
  target?: React.RefObject<HTMLDivElement> | React.RefObject<HTMLDivElement>[];
  /**
   * Specified if the property that holds the metadata for the api response is not the root property `meta`
   * (there is a stash GET that has this property with a different name)
   */
  metaAttribute?: string;
  /**
   * Initial data to use as the first page
   * If this is provided, the first fetch won't run again, it will go straight to the second page of data
   */
  initialData?: T;
  /**
   * A list of functions to be applied on every page of data to get some derived data based on what we fetch
   * Can be used for example to update properties of the api response based on some local state
   */
  mutations?: ((page: T) => Promise<T> | T)[];
  /**
   * Limit how many pages will be fetched
   */
  maxPageCount?: number;
}

/**
 * Hook for fetching paginated data.
 * The data should respect the {objects,meta} API pagination schema
 * @returns An array of data pages along with metadata
 */

const useInfiniteScroll = <QueryResult>({
  firstPageUrl,
  queryKey,
  getKey,
  target,
  metaAttribute = 'meta',
  initialData,
  mutations,
  maxPageCount,
}: Params<QueryResult>): InfiniteScrollResult<QueryResult> => {
  //This function is responsible of fetching the pages of data
  //Initially no pageParam will be present on the context param. In that case we use the firstPageUrl
  const fetcher: QueryFunction<QueryResult> = async ({
    pageParam,
  }: QueryFunctionContext<QueryKey, { url: string }>) => {
    const url = pageParam?.url ?? firstPageUrl;
    const baseResponse: Promise<QueryResult> = apiQueryClient(url);
    // Apply each of the provided mutations
    if (mutations) {
      // This will be a promise that has chained on `then` all the mutations
      // When the promise resolves with the response from the API, the mutations will be sequentially applied
      const response = mutations.reduce(
        (acc, currentMutation) => acc.then(currentMutation),
        baseResponse,
      );

      return response;
    }
    return baseResponse;
  };

  //Keep the page index
  const currentIndex = useRef(0);

  const { data, error, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery<QueryResult, unknown, QueryResult>(queryKey, fetcher, {
      // This function returns the `pageParams` object passed to the fetcher function to get the next page of data
      getNextPageParam: lastPage => {
        // Since we expect the response to respect out backend response schema,
        // we should have the next property present to indicate whether there is more data to be fetched or not
        if (!(lastPage as any)[metaAttribute]?.next) {
          //No more data to be fetched
          return undefined;
        }

        const nextUrl = getKey(currentIndex.current + 1, lastPage);
        if (!nextUrl) {
          //No more data to be fetched
          return undefined;
        }

        // if maxPageCount provided, make sure to not go further
        // maxPageCount - 1 because currentIndex starts from 0
        if (maxPageCount && currentIndex.current >= maxPageCount - 1) {
          return undefined;
        }

        // There is a page, so increment the page index
        currentIndex.current++;
        return { url: nextUrl };
      },
      initialData: initialData
        ? {
            // The page params associated with the first page of data has to be `undefined` based on the docs of React-Query
            pageParams: [undefined],
            pages: [initialData],
          }
        : undefined,
    });

  // be sure when the url changes, a new api call is made
  useEffect(() => {
    refetch();
  }, [firstPageUrl]);

  const isEmpty =
    (data?.pages[0] as any)?.objects?.length === 0 ||
    (data?.pages[0] as any)?.[metaAttribute] === undefined;

  const isFirstLoading = !data && !error;

  // Register the intersection observer to fetch the next page when the target elements get into the viewport
  // This useEffect runs on each render since we probably have different target elements after each page of data is fetched
  useEffect(() => {
    if (!target) return;

    const intersectionObserver = new IntersectionObserver(
      entries => {
        for (const { isIntersecting } of entries) {
          if (isIntersecting && !isFetching && hasNextPage) {
            fetchNextPage();
          }
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (Array.isArray(target)) {
      target.forEach(ref => {
        if (ref.current) {
          intersectionObserver.observe(ref.current);
        }
      });
    } else if (target.current) {
      intersectionObserver.observe(target.current);
    }

    return () => {
      // Unregister the elements since they shouldn't be valid anymore
      if (Array.isArray(target)) {
        target.forEach(ref => {
          if (ref.current) {
            intersectionObserver.unobserve(ref.current);
          }
        });
      } else if (target.current) {
        intersectionObserver.unobserve(target.current);
      }
    };
  });

  useEffect(() => {
    //Reset the page index
    currentIndex.current = 0;
  }, [firstPageUrl, queryKey, getKey]);

  const queryClient = useQueryClient();

  // React Query gives us a function to perform this mutation, but it requires us to give the queryKey as param each time
  // Since we know the queryKey here, we can bind this argument and get a new function specific for this query
  const mutate = (
    newData: QueryResult[] | ((prevData: QueryResult[]) => QueryResult[]),
  ) => {
    const pages =
      typeof newData === 'function' ? newData(data?.pages ?? []) : newData;
    queryClient.setQueryData<InfiniteData<QueryResult>>(queryKey, data => ({
      pages,
      pageParams: data?.pageParams ?? [],
    }));
  };

  return {
    data: firstPageUrl === '' ? [] : data?.pages ?? [],
    error,
    isFetching,
    isFirstLoading,
    isEmpty,
    hasNextPage,
    refetch,
    mutate,
  };
};

export default useInfiniteScroll;
