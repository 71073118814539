import { CollectionContext } from '../collection/CollectionProvider';
import {
  CollectionProviderAction,
  CollectionProviderContextType,
  CollectionProviderState,
} from '../collection/CollectionProviderTypes';
import useCreateContextConsumer from './useCreateContextConsumer';

const useCollection = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    CollectionProviderState,
    CollectionProviderAction,
    CollectionProviderContextType
  >({ context: CollectionContext, withLogging: true });

  return {
    ...contextConsumer,
    collectionDispatch: dispatch,
  };
};

export default useCollection;
