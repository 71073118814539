import { useRouter } from 'next/router';
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { CollectionData } from 'src/types/models';
import { normalizeCollection } from 'utils/normalizers/collection';
import * as CollectionApi from '../../api/Collection';
import useActivity from '../hooks/useActivity';
import useAuth from '../hooks/useAuth';
import useProfile from '../hooks/useProfile';
import {
  CollectionProviderContextType,
  CollectionProviderReducer,
  CollectionProviderState,
} from './CollectionProviderTypes';
import collectionReducer from './CollectionReducer';

const INITIAL_STATE: CollectionProviderState = {
  collection: undefined,
};

export const CollectionContext = createContext<CollectionProviderContextType>({
  dispatch: () => {
    return;
  },
  state: INITIAL_STATE,
});

CollectionContext.displayName = 'CollectionContext';

interface CollectionProviderProps {
  /**
   * @param {CollectionData} collection - The normalized collection
   * to be stored in the context
   * @default undefined
   */
  collection?: CollectionData;
  /**
   * @param {boolean} isInitialRequestLoggedIn - True if the initial
   * request for the collection was made with a SI token, false if NSI.
   * Used for telling whether to refetch the collection or not upon
   * signing in to update the progress.
   * @default false
   */
  isInitialRequestLoggedIn?: boolean;
}

/**
 * @param {CollectionData} collection - The normalized collection
 * to be stored in the context
 * @default undefined
 * @param {Source} source - The normalized source of the collection,
 * if we are viewing a particular source
 * @default undefined
 * @param {boolean} isInitialRequestLoggedIn - True if the initial
 * request for the collection was made with a SI token, false if NSI.
 * Used for telling whether to refetch the collection or not upon
 * signing in to update the progress.
 * @default false
 */
const CollectionProvider: React.FC<CollectionProviderProps> = ({
  collection,
  isInitialRequestLoggedIn = false,
  children,
}) => {
  const [state, dispatch] = useReducer<CollectionProviderReducer>(
    collectionReducer,
    { collection },
  );
  const { activity, activityDispatch } = useActivity();
  const { profile } = useProfile();
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!isInitialRequestLoggedIn && isLoggedIn && collection) {
      CollectionApi.getCollection({ id: collection?.id ?? 0 })
        .then(collectionResponse => {
          if (collectionResponse) {
            dispatch({
              type: 'set-collection',
              payload: { collection: normalizeCollection(collectionResponse) },
            });
          }
        })
        .catch(_ => undefined);
    }
  }, [isLoggedIn, collection]);

  useEffect(() => {
    dispatch({
      type: 'set-collection',
      payload: {
        collection,
      },
    });
  }, [collection?.id]);

  const readTodayCount = useRef<number>(activity?.readToday ?? 0);
  useEffect(() => {
    if (collection) {
      dispatch({
        type: 'update-reads',
        payload: {
          reads: activity?.readIdeas,
          sourceId: parseInt((router.query.id as string) ?? '-1'),
        },
      });

      // If the daily goal (or modulo) has been exceeded,
      // we sync the reads. This usually happens in the
      // Activity reducer (when dispatching 'increment-read'),
      // but when collectionId is present, it is skipped.
      //
      // We need to delay the sync from there to here in order
      // to have the readIdeas list intact to see which idea
      // has been read before the sync (as the sync clears the list).
      if (
        profile &&
        activity &&
        profile.dailyReadingGoal -
          (activity.readToday % profile.dailyReadingGoal) <=
          activity.readToday - readTodayCount.current
      ) {
        activityDispatch({
          type: 'sync-reads',
          payload: { collectionId: collection.id },
        });
      }
      readTodayCount.current = activity?.readToday ?? 0;
    }
  }, [activity?.readToday]);

  const contextValue = useMemo<CollectionProviderContextType>(
    () => ({
      dispatch,
      state,
    }),
    [dispatch, state],
  );

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;
