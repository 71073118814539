import {
  Button,
  color,
  Flex,
  spacing,
  Text,
  useColorMode,
  useToast,
} from 'deepstash-ui';
import { typography } from 'theme/typography';
import useRouter from 'hooks/useRouter';
import React from 'react';
import { BORDER_RADIUS_L, CONTENT_PADDING_M, PAGE_PADDING } from 'theme/size';
import { UserStash } from 'types';
import { MAX_STASH_LEN, TOAST_TIME } from 'utils/constants';
import useActivity from 'src/providers/hooks/useActivity';
import useProfile from 'src/providers/hooks/useProfile';
import { updateUserProf } from 'utils/profile.utils';
import { Analytics, Events } from 'src/services/analytics';

const STASH_TITLE_LIMIT = 30;

const useStashModalInternals = (
  stash?: UserStash,
  emoji?: string,
  stashName?: string,
  setStashName?: (name: string) => void,
  setEmoji?: (emoji: string) => void,
  onClose?: () => void,
  onCreateNewStashCb?: (id: number) => void,
) => {
  const { activity, createStash, updateStash, deleteStash } = useActivity();

  const router = useRouter();
  const inStashPage = router.asPath.startsWith(`/stash/${stash?.id}/`);

  const { colorMode } = useColorMode();
  const { profile, profileDispatch } = useProfile();

  const toast = useToast();

  /**
   * function handling the creation of a new stash
   * @returns Promise<void>
   */
  const addNewStash = async () => {
    if (!activity) return;

    const name = stashName?.trim() ?? '';

    if (name === '') {
      Analytics.logEvent({
        eventName: Events.stability.warningEvent,
        properties: {
          element: 'stash',
          warning: 'no-name',
        },
        platforms: ['amplitude'],
      });
      toast({
        title: 'No Stash Name',
        description: 'Please enter a name for the new stash!',
        status: 'warning',
        duration: TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    if (emoji === '') {
      toast({
        title: 'No Emoji',
        description: 'Please select a valid emoji!',
        status: 'warning',
        duration: TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    if (name.length > STASH_TITLE_LIMIT) {
      Analytics.logEvent({
        eventName: Events.stability.warningEvent,
        properties: {
          element: 'stash',
          warning: 'too-long',
        },
        platforms: ['amplitude'],
      });
      toast({
        title: 'Title Too Long!',
        description:
          'The title is too long! Maybe pick something more concise!',
        status: 'warning',
        duration: TOAST_TIME,
        isClosable: true,
      });
      return;
    }

    createStash({
      name,
      emoji: emoji ?? '',
      onSuccess: (id: number) => {
        toast({
          title: 'Creation Successful',
          description: 'Stash ' + name + ' has been created!',
          status: 'success',
          duration: TOAST_TIME,
          isClosable: true,
        });
        onCreateNewStashCb?.(id);

        //Check the onboarding step
        if (profile && !profile?.didOnboardingStashing) {
          //Local update
          profileDispatch({
            type: 'mutate',
            payload: {
              profile: {
                didOnboardingStashing: true,
              },
            },
          });
          //Api call
          updateUserProf({ didOnboardingStashing: true, id: profile.id });
        }

        // this resets the state
        setEmoji?.('😎');
        setStashName?.('');
        onClose?.();

        Analytics.logEvent({
          eventName: Events.organising.createStash,
          properties: {
            stashName: name,
            from: 'side-menu',
          },
          platforms: ['amplitude'],
        });
      },
    });
  };

  /**
   * function handling the update logic for stash
   * @returns Promise<void>
   */
  const onUpdateStash = async () => {
    const trimmedStashName = stashName?.trim() ?? '';

    if (trimmedStashName.length > MAX_STASH_LEN) {
      Analytics.logEvent({
        eventName: Events.stability.warningEvent,
        properties: {
          element: 'stash',
          warning: 'too-long',
        },
        platforms: ['amplitude'],
      });
      toast({
        title: 'Title Too Long!',
        description: `It's ${
          trimmedStashName.length - MAX_STASH_LEN
        } character${
          trimmedStashName.length - MAX_STASH_LEN === 1 ? '' : 's'
        } too many! Maybe pick something more concise!`,
        status: 'warning',
        duration: TOAST_TIME,
        isClosable: true,
      });
    } else {
      if (!stash) {
        return;
      }
      const { id } = stash;

      updateStash({
        id,
        name: trimmedStashName,
        emoji,
        onSuccess: () => {
          Analytics.logEvent({
            eventName: Events.organising.editStash,
            properties: {
              stashId: id,
              element: 'name',
            },
            platforms: ['amplitude'],
          });
          toast({
            title: 'Update Successful',
            description: 'Stash ' + trimmedStashName + ' has been updated!',
            status: 'success',
            duration: TOAST_TIME,
            isClosable: true,
          });
        },
      });
    }

    onClose?.();
  };

  const closeAffirmative = async (onClose: () => void) => {
    onClose();
    if (!activity || !stash) return;

    deleteStash({
      id: stash.id,
      onSuccess: () => {
        Analytics.logEvent({
          eventName: Events.organising.deleteStash,
          properties: {
            stashId: stash.id,
            completed: true,
          },
          platforms: ['amplitude'],
        });
        if (inStashPage) {
          router.push('/');
        }
      },
    });
  };

  const closeNegative = (onClose: () => void) => {
    stash &&
      Analytics.logEvent({
        eventName: Events.organising.deleteStash,
        properties: {
          stashId: stash.id,
          completed: false,
        },
        platforms: ['amplitude'],
      });

    onClose();
  };

  /**
   * function that handles the delete logic for a stash
   * @param e Mouse event
   */
  const onDeleteStash = (e: React.MouseEvent) => {
    e.stopPropagation();
    toast({
      id: 'delete-stash',
      duration: 100000,
      render: onClose => (
        <Flex
          color={color[colorMode].top}
          align="center"
          backgroundColor={color.failure.default}
          borderRadius={BORDER_RADIUS_L}
          mb={PAGE_PADDING}
          shadow={spacing.XXS.rem}
          p={spacing.toRem(CONTENT_PADDING_M)}
        >
          <Text
            {...typography.text.small}
            textAlign="left"
            px={spacing.toRem(CONTENT_PADDING_M)}
          >
            Are you sure you want to delete <b>{stashName}</b>?
          </Text>
          <Button
            borderRadius={BORDER_RADIUS_L}
            size="lg"
            onClick={() => closeAffirmative(onClose)}
            h={spacing.HUGE.rem}
            w={spacing.toRem(80)}
            backgroundColor={color.light.top}
            color={color.failure.default}
            {...typography.text.medium}
            mr={spacing.toRem(CONTENT_PADDING_M)}
            data-testid="side-stash-confirm-delete"
          >
            Delete
          </Button>
          <Button
            borderRadius={BORDER_RADIUS_L}
            size="lg"
            onClick={() => closeNegative(onClose)}
            h={spacing.HUGE.rem}
            w={spacing.toRem(80)}
            backgroundColor={color.light.top}
            color={color.success}
            {...typography.text.medium}
          >
            Cancel
          </Button>
        </Flex>
      ),
    });
  };

  return {
    addNewStash,
    onUpdateStash,
    onDeleteStash,
  };
};

export default useStashModalInternals;
