import {
  Box,
  color,
  Divider,
  Flex,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import NextLink from 'src/components/navigation/NextLink';
import React, { useCallback } from 'react';
import { UserData, UsersModalType } from 'types';
import useProfile from 'src/providers/hooks/useProfile';
import ProfilePicture from '../social/ProfilePicture';
import FollowUserButton from '../buttons/FollowUserButton';
import { onLinkClick } from 'utils/global';
import { Analytics, Events } from 'src/services/analytics';

interface UserFollowRowProps {
  user: UserData;
  onUserClick?: () => void;
  /**
   * Used for analytics, to know the location of the component
   */
  usecase?: UsersModalType;
  onFollowSuccess?: (isFollowing: boolean) => void;
  showSeparator?: boolean;
  photoCornerComponent?: React.ReactElement;
}

const UserFollowRow: React.FC<UserFollowRowProps & StyleProps> = ({
  user,
  onUserClick: onUserClickCb,
  usecase,
  onFollowSuccess,
  showSeparator = true,
  photoCornerComponent,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const { profile } = useProfile();

  const getDisplayName = useCallback(
    (user: UserData) => {
      const fullName =
        `${user.firstName} ${user.lastName}`.length > 20
          ? `${user.firstName} ${user.lastName}`.substring(0, 17) + '...'
          : `${user.firstName} ${user.lastName}`;

      return fullName.trim().length > 0 ? fullName : user.username;
    },
    [user],
  );

  const displayUser = (user: UserData) => {
    return (
      <Flex>
        <Box position="relative">
          <Flex w="40px" justifyContent="center" alignItems="center">
            <ProfilePicture
              photo={user.photo ?? ''}
              imageBlurhash={user?.photo_blurhash ?? ''}
              size="l"
            />
          </Flex>
          {photoCornerComponent}
        </Box>
        <Flex
          ml={spacing.XS.rem}
          w="100%"
          flexDir="column"
          justifyItems="flex-start"
        >
          <PrimaryText
            type="bold"
            color={color[colorMode].textSecondary}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {getDisplayName(user)}
          </PrimaryText>
          <PrimaryText
            size="xs"
            color={color[colorMode].textSecondary}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              overflowWrap: 'anywhere',
            }}
          >
            {'@' + user.username}
          </PrimaryText>
        </Flex>
      </Flex>
    );
  };

  const onUserClick = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    user: UserData,
  ) => {
    onLinkClick(ev);
    onUserClickCb?.();
    if (usecase) {
      Analytics.logEvent({
        eventName: Events.modals.usersModal,
        properties: {
          type: usecase,
          id: user.username,
        },
        platforms: ['amplitude'],
      });
    }
  };

  return (
    <Box key={user.id} width="100%">
      {showSeparator && <Divider borderColor={color[colorMode].underground} />}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        {...props}
      >
        <NextLink href={`/u/${user.username}`} passHref>
          <Link
            w="100%"
            onClick={ev => onUserClick(ev, user)}
            _focus={undefined}
            _active={undefined}
          >
            {displayUser(user)}
          </Link>
        </NextLink>
        <FollowUserButton
          display={profile?.id === user.id ? 'none' : 'inline-flex'}
          userId={user.id}
          onFollowSuccess={onFollowSuccess}
        />
      </Flex>
    </Box>
  );
};

export default UserFollowRow;
