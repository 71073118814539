import {
  ArticleApiResponse,
  GoogleBookItem,
  InsightApiResponse,
  ScrappedArticleApiResponse,
  SourceReactionObjectApiResponse,
  SourceTopReactionApiResponse,
  UserCommentApiResponse,
  UnknownSourceApiResponse,
} from 'api/api.types';
import {
  BookResultModel,
  Idea,
  ScrappedSource,
  IdeaSyncStatus,
  Source,
  UserComment,
  SourceTopReaction,
  UserReaction,
  UserProfile,
} from 'types/models';
import { curateIdeaHtml } from 'utils/global';
import { getSourceThemeColor } from 'utils/source.utils';
import { normalizeUserData } from './profile';
import { normalizeStash } from './stash';
import { normalizeTopic } from './topics';
import { Normalizer } from './types';

export const normalizeIdea = (
  idea: InsightApiResponse,
  isDraft = false,
): Idea => {
  return {
    //The backend can sends null
    authorName: idea.author_name ?? undefined,
    content: curateIdeaHtml(idea.content),
    createdAt: idea.created_at,
    hashtags: idea.hashtags,
    id: idea.id,
    localId: idea.id,
    image: idea.image_compressed,
    imageBlurhash: idea.image_blurhash,
    isFeaturedInCollection: idea.is_featured_in_collection ?? false,
    order: idea.order,
    quality: idea.quality,
    sourceId: idea.articleId ?? idea.article_id,
    status: idea.status,
    topics: [],
    totalReads: idea.total_reads,
    totalSaves: idea.total_saves,
    // [Like functionality]
    // totalLikes: idea.total_likes,
    title: idea.title,
    isDraft: isDraft,
    source: idea.article ? normalizeSource(idea.article) : undefined,
    timeToRead:
      typeof idea.time_to_read === 'string'
        ? Number.parseInt(idea.time_to_read)
        : idea.time_to_read,
    userId: Number.parseInt(idea.user ?? ''),
    syncStatus: IdeaSyncStatus.SYNCED,
    type: idea.type,
  };
};

export const normalizeSource: Normalizer<
  ArticleApiResponse,
  Source
> = source => {
  return {
    id: source.id,
    ideas: (source.blocks ?? []).map(idea => normalizeIdea(idea)),
    publishStash: source.stash_published_in
      ? normalizeStash(source.stash_published_in)
      : undefined,
    status: source.status,
    title: source.title,
    titleInCollection: source.title_in_collection,
    sourceType: source.source_type,
    sourceSubtype: source.source_subtype,
    suggestedBlocks: source.suggested_blocks ?? [],
    userData: source.user_data
      ? normalizeUserData(source.user_data)
      : { firstName: '', id: 0, lastName: '' },
    author: source.author,
    content: source.content,
    description: source.description,
    image: source.image_compressed,
    imageColor: source.image_color
      ? '#' + source.image_color
      : getSourceThemeColor(source.source_type, 'light'),
    imageBlurhash: source.image_blurhash,
    similarArticles: (source.similar_articles ?? []).map(article =>
      normalizeSource(article),
    ),
    topics: (source.topics ?? []).map(topic => normalizeTopic(topic)),
    url: source.url,
    context: source.context,
    suggestedTopics: source.suggested_hashtags ?? [],
    totalComments: source.total_comments,
    totalReactions: source.total_reactions,
    topReactions: source?.top_reactions?.map(normalizeTopReaction),
    totalReads: source.total_reads,
    ideaCount: source.total_blocks,
    publishers: source.publishers,
    seo: source.seo,
  } as Source;
};

// Normalizers for data from google books API

export function normalizeBooks(
  inputBooks: GoogleBookItem[],
): BookResultModel[] {
  const books: BookResultModel[] = [];
  inputBooks.forEach((item: GoogleBookItem) => {
    books.push({
      id: item.id,
      authors: item.volumeInfo.authors || [],
      title: item.volumeInfo.title,
      image: item.volumeInfo.imageLinks?.thumbnail || undefined,
      bookLink: item.volumeInfo.canonicalVolumeLink,
      description: item.volumeInfo.description || '',
    });
  });
  return books;
}

export function normalizeBook(inputBook: GoogleBookItem): BookResultModel {
  return {
    id: inputBook.id,
    authors: inputBook.volumeInfo.authors || [],
    title: inputBook.volumeInfo.title,
    image: inputBook.volumeInfo.imageLinks?.thumbnail || undefined,
    bookLink: inputBook.volumeInfo.canonicalVolumeLink,
    description: inputBook.volumeInfo.description || '',
  };
}

export const normalizeScrappedSource: Normalizer<
  ScrappedArticleApiResponse,
  ScrappedSource
> = article => {
  return {
    ...normalizeSource(article),
    reviewStatus: article.review_status,
  };
};

export const normalizeComments: Normalizer<
  UserCommentApiResponse,
  UserComment
> = comment => {
  return {
    id: comment.id,
    createdAt: comment.created_at,
    textHtml: comment.text_html,
    userData: normalizeUserData(comment.user_data),
    source: comment?.article ? normalizeSource(comment.article) : undefined,
  };
};

export const normalizeTopReaction: Normalizer<
  SourceTopReactionApiResponse,
  SourceTopReaction
> = topReaction => {
  return {
    reactionId: topReaction.reaction_id,
    usersCount: topReaction.total_users,
  };
};

export const normalizeUserReaction: Normalizer<
  SourceReactionObjectApiResponse,
  UserReaction
> = userReaction => {
  return {
    reaction: userReaction.reaction,
    user: normalizeUserData(userReaction.user),
  };
};

export const normalizeUnknownSource = (
  unknownSource: UnknownSourceApiResponse,
  currentUser: UserProfile,
) =>
  ({
    id: unknownSource.id,
    ideas: [],
    title: unknownSource.title,
    sourceSubtype: unknownSource.source_subtype,
    sourceType: unknownSource.source_type,
    status: unknownSource.status,
    userData: {
      ...currentUser,
    },
    suggestedTopics: [],
    totalComments: 0,
    topReactions: [],
    imageColor: getSourceThemeColor(unknownSource.source_type, 'light'),
    totalReactions: 0,
  } as Source);
