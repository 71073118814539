import { SourceReactionsApiResponse } from 'api/api.types';
import { Box, color, Flex, spacing, Spinner, useColorMode } from 'deepstash-ui';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactionsUserList from 'src/components/reactions/ReactionsUserList';
import { Reaction, UserReaction } from 'types/models';
import { normalizeUserReaction } from 'utils/normalizers/source';

interface ReactionSceneProps {
  sourceId: number;
  reactionId: number;
  allReactions: Reaction[];
  /**
   * After the API call for the first reaction data page was completed, pass the `article_reactions` up the component tree
   */
  setSourceReactions?: (reactionInfo: Record<number, number>) => void;
}

const SourceReactionScene: React.FC<ReactionSceneProps> = ({
  sourceId,
  reactionId,
  allReactions,
  setSourceReactions,
}) => {
  const { colorMode } = useColorMode();

  const target = useRef<HTMLDivElement>(null);

  const initialUrl = useMemo(() => {
    if (reactionId === -1) {
      return `article/${sourceId}/reactions/`;
    }
    return `/article/${sourceId}/reactions/?reaction_id=${reactionId}`;
  }, [sourceId, reactionId]);

  const {
    data: sceneData,
    isFirstLoading: isSceneFirstLoading,
    isFetching: isSceneFetching,
  } = useInfiniteScroll<SourceReactionsApiResponse>({
    firstPageUrl: initialUrl,
    queryKey: ['reactionsDropdown', sourceId, reactionId],
    getKey: (_index, previousPageData) => {
      //reached the end
      if (previousPageData && previousPageData.objects.length === 0)
        return undefined;

      return previousPageData.meta.next?.replace('/api/v2/', '');
    },
    target,
  });

  //We need to set the source reactions only once since subsequent page requests will have the same value for this property
  const didUpdateSourceReaction = useRef(false);
  useEffect(() => {
    if (didUpdateSourceReaction.current) {
      return;
    }
    if (sceneData[0] && sceneData[0].article_reactions) {
      setSourceReactions?.(sceneData[0].article_reactions);
      didUpdateSourceReaction.current = true;
    }
  }, [sceneData]);

  const reactions: UserReaction[] = useMemo(() => {
    return sceneData
      .map(reaction => reaction.objects.map(normalizeUserReaction))
      .flat();
  }, [sceneData]);

  return (
    <Box>
      {!isSceneFirstLoading && (
        <ReactionsUserList reactions={reactions} allReactions={allReactions} />
      )}
      {isSceneFetching && (
        <Flex
          justifyContent="center"
          align="center"
          mt={spacing.toRem(120)}
          width={{ base: '300px', lg: '340px' }}
        >
          <Spinner color={color[colorMode].textSecondary} />
        </Flex>
      )}
      <Box h="32px" ref={target} />
    </Box>
  );
};

export default SourceReactionScene;
