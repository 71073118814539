import { SourceProviderReducer } from './SourceProviderTypes';

const sourceReducer: SourceProviderReducer = (state, action) => {
  switch (action.type) {
    case 'set-source': {
      const { source } = action.payload;
      return {
        ...state,
        source,
      };
    }
    case 'set-similar-sources': {
      const { similarSources } = action.payload;
      return {
        ...state,
        similarSources,
      };
    }
    case 'set-related-posts': {
      const { relatedPosts } = action.payload;
      return {
        ...state,
        relatedPosts,
      };
    }
    case 'toggle-save-idea': {
      const { ideaId, isSaved } = action.payload;

      const ideas = [...(state?.source?.ideas ?? [])];
      const ideaIndex = ideas.findIndex(({ id }) => id === ideaId);
      if (ideaIndex !== -1) {
        ideas[ideaIndex].totalSaves += isSaved ? 1 : -1;
      }

      return {
        ...state,
        source: state.source
          ? {
              ...state.source,
              ideas,
            }
          : undefined,
      };
    }
    case 'toggle-save-source': {
      const { isSaved } = action.payload;

      const ideas = [...(state?.source?.ideas ?? [])];
      ideas.forEach(idea => (idea.totalSaves += isSaved ? 1 : -1));

      return {
        ...state,
        source: state.source
          ? {
              ...state.source,
              ideas,
            }
          : undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default sourceReducer;
