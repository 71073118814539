import {
  color,
  Flex,
  FlexProps,
  spacing,
  useColorMode,
  useIsDesktopView,
} from 'deepstash-ui';
import { default as React, useRef } from 'react';

interface BaseCardProps {
  children?: React.ReactNode;
  hasShadow?: boolean;
  hasHoverEffect?: boolean;
  cardSize?: 'sm' | 'lg';
}

const BaseCard = React.forwardRef<HTMLDivElement, BaseCardProps & FlexProps>(
  (
    {
      children,
      /**
       * True if we should draw a shadow underneath
       * @default false
       */
      hasShadow,
      /**
       * True if the card should have a hover effect
       * @default false
       */
      hasHoverEffect,
      /**
       * Used to hint the size of the card to the component,
       * to better tailor the shadow and hover effect, if
       * applied.
       *
       * It only works on desktop devices.
       * @default lg
       */
      cardSize = 'lg',
      ...props
    },
    ref,
  ) => {
    const { colorMode } = useColorMode();
    const isDesktopView = useIsDesktopView();

    const { current: shadowSize } = useRef(cardSize === 'lg' ? 16 : 8);

    return (
      <Flex
        ref={ref}
        width="100%"
        height="100%"
        color={color[colorMode].text}
        borderRadius="1rem"
        position="relative"
        transition="all 0.3s cubic-bezier(.11,.57,.44,.8)"
        overflow="hidden"
        boxShadow={
          hasShadow
            ? `0px ${spacing.toRem(shadowSize)} 0px 0px ${
                color[colorMode].underground
              }`
            : ''
        }
        {...props}
        backgroundColor={
          props.backgroundColor ?? props.bgColor ?? color[colorMode].top
        }
        _hover={
          hasHoverEffect && isDesktopView
            ? {
                ...props._hover,
                transform: `translateY(-${spacing.toRem(shadowSize / 4)})`,
                boxShadow: `0px ${
                  hasShadow
                    ? spacing.toRem(shadowSize * 1.5)
                    : spacing.toRem(shadowSize / 4)
                } 0px 0px ${color[colorMode].underground}CC`,
              }
            : { ...props._hover }
        }
      >
        {children}
      </Flex>
    );
  },
);

export default BaseCard;
