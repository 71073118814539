import React from 'react';
import { StyleProps } from 'deepstash-ui';
import { Source } from 'types/models';
import SourceCollapsedLarge from './SourceCollapsedLarge';
import SourceCollapsedSmall from './SourceCollapsedSmall';
import SourceCollapsedMedium from './SourceCollapsedMedium';
import SourceCollapsedFull from './SourceCollapsedFull';

export interface SourceCollapsedProps {
  source?: Source;
  preload?: boolean;
  showTopics?: boolean;
  shouldHideCta?: boolean;
  showContext?: boolean;
  readCount?: number;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  overrideAuthorComponent?: JSX.Element;
  shouldOpenInNewTab?: boolean;
  titleOverride?: string;
}

const SourceCollapsed: React.FC<
  SourceCollapsedProps &
    StyleProps & { size?: 'small' | 'medium' | 'large' | 'full' }
> = ({
  source,
  headingSize,
  readCount,
  size = 'large',
  showContext = true,
  preload,
  showTopics,
  ...props
}) => {
  switch (size) {
    case 'full':
      return (
        <SourceCollapsedFull
          readCount={readCount}
          preload={preload}
          headingSize={headingSize}
          {...props}
        />
      );
    case 'large':
      return source ? (
        <SourceCollapsedLarge
          readCount={readCount}
          source={source}
          {...props}
          preload={preload}
          showContext={showContext}
          showTopics={showTopics}
        />
      ) : null;
    case 'medium':
      return source ? (
        <SourceCollapsedMedium source={source} {...props} />
      ) : null;
    default:
      return source ? (
        <SourceCollapsedSmall source={source} preload={preload} />
      ) : null;
  }
};

export default SourceCollapsed;
