import {
  AppStoreButton,
  AppStoreColoredSvg,
  color,
  Flex,
  FullStarSvg,
  GooglePlayColoredSvg,
  HalfStarSvg,
  PrimaryText,
  spacing,
  StyleProps,
  typography,
  useColorMode,
  useDeviceDetect,
  useIsMobileView,
} from 'deepstash-ui';
import { MetricItem } from 'pages/curators';
import React from 'react';
import commonStrings from 'utils/strings/commonStrings';

const metricsItems: MetricItem[] = [
  {
    icons: (
      <Flex>
        <GooglePlayColoredSvg boxSize="1.5rem" />
        <AppStoreColoredSvg boxSize="1.5rem" ml="0.5rem" />
      </Flex>
    ),
    text: commonStrings.installCount,
  },
  {
    icons: (
      <Flex>
        {[0, 1, 2, 3].map((_, index) => (
          <FullStarSvg
            key={index}
            color={color.light.secondary.default}
            boxSize="1.5rem"
          />
        ))}
        <HalfStarSvg color={color.light.secondary.default} boxSize="1.5rem" />
      </Flex>
    ),
    text: commonStrings.appScore,
  },
];

interface LandingStoreCardProps {
  isAboutPageVariant?: boolean;
}

const LandingStoreCard: React.FC<LandingStoreCardProps & StyleProps> = ({
  isAboutPageVariant = false,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  const { isIos } = useDeviceDetect();

  const whiteCardStyling: StyleProps = {
    bgColor: color[colorMode].top,
    borderRadius: spacing.toRem(16),
    py: spacing.toRem(12),
    px: spacing.toRem(16),
    w: spacing.toRem(164),
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      px={{ base: '1.5rem', lg: '2rem' }}
      pb={{ base: '2rem', lg: '3rem' }}
      {...typography.primaryTextFonts.medium.regular}
      color={color[colorMode].text}
      borderRadius="1rem"
      alignContent="center"
      justifyContent="center"
      height="100%"
      w="100%"
      {...props}
    >
      {isMobileView ? (
        <Flex flexDir="column" alignItems="center" w="100%">
          <AppStoreButton
            store={isIos ? 'Apple' : 'GooglePlay'}
            id={`landing-store-card-app-store-button-${
              isIos ? 'ios' : 'android'
            }`}
          />
          <Flex w="100%" justifyContent="center">
            <Flex
              flexDir="column"
              alignItems="center"
              py={spacing.toRem(8)}
              w="50%"
            >
              <Flex>{metricsItems[0].icons}</Flex>
              <PrimaryText
                color={color[colorMode].textSecondary}
                type="bold"
                size="m"
              >
                {metricsItems[0].text}
              </PrimaryText>
            </Flex>
            <Flex
              flexDir="column"
              alignItems="center"
              py={spacing.toRem(8)}
              w="50%"
            >
              <Flex>{metricsItems[1].icons}</Flex>
              <PrimaryText
                color={color[colorMode].textSecondary}
                type="bold"
                size="m"
                alignSelf="center"
              >
                {metricsItems[1].text}
              </PrimaryText>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex>
          <Flex
            flexDir="column"
            mr={spacing.M.rem}
            alignItems={isAboutPageVariant ? 'flex-end' : 'center'}
          >
            <AppStoreButton store="Apple" width="212px" />
            <Flex
              flexDir="column"
              alignItems="center"
              {...(isAboutPageVariant ? { ...whiteCardStyling } : undefined)}
            >
              <Flex>{metricsItems[0].icons}</Flex>
              <PrimaryText color={color[colorMode].text} type="bold" size="m">
                {metricsItems[0].text}
              </PrimaryText>
            </Flex>
          </Flex>
          <Flex
            flexDir="column"
            alignItems={isAboutPageVariant ? 'flex-start' : 'center'}
          >
            <AppStoreButton store="GooglePlay" width="212px" />
            <Flex
              flexDir="column"
              alignItems="center"
              {...(isAboutPageVariant ? { ...whiteCardStyling } : {})}
            >
              <Flex>{metricsItems[1].icons}</Flex>
              <PrimaryText color={color[colorMode].text} type="bold" size="m">
                {metricsItems[1].text}
              </PrimaryText>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default LandingStoreCard;
