import { Flex } from 'deepstash-ui';
import React from 'react';
import { UserComment } from 'types/models';
import { TRANSITION_TIME } from 'utils/constants';
import CommentUserEntry from './CommentUserEntry';

interface CommentsUserListProps {
  comments: UserComment[];
}

const CommentsUserList: React.FC<CommentsUserListProps> = ({ comments }) => {
  return (
    <Flex
      flexDir="column"
      width="100%"
      alignItems="center"
      transition={`${TRANSITION_TIME}s`}
    >
      {comments.map((userComment, index) => (
        <CommentUserEntry
          key={`comment-item-${userComment.id}-${index}`}
          comment={userComment}
        />
      ))}
    </Flex>
  );
};

export default CommentsUserList;
