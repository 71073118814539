import {
  Box,
  StyleProps,
  Flex,
  useColorMode,
  color,
  PrimaryText,
  spacing,
} from 'deepstash-ui';
import React from 'react';

interface OwnProps {
  ideasCount: number;
}

const StashOptionsIdeasCount: React.FC<OwnProps & StyleProps> = ({
  ideasCount,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex align="center">
      <Box
        backgroundColor={color[colorMode].surface}
        color={color[colorMode].primary.default}
        borderRadius={spacing.M.rem}
        px={spacing.M.rem}
        py={spacing.XXS.rem}
        {...props}
      >
        <PrimaryText type="bold" fontSize="12px">
          {ideasCount}
        </PrimaryText>
      </Box>
    </Flex>
  );
};

export default StashOptionsIdeasCount;
