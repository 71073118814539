import { UserSubscriptionType } from 'types';

/**
 * The maximum number of stashes a free user can create.
 */
const STASHES_NUMBER_PER_FREE_USER = 1;

/**
 * The number of saved ideas a user can see.
 */
export const AVAILABLE_SAVED_IDEAS_PER_FREE_USER = 10;

/**
 * The number of liked ideas a user can see.
 */
export const AVAILABLE_LIKED_IDEAS_PER_FREE_USER = 10;

/**
 * The number of saved sources a user can see.
 */
export const AVAILABLE_SAVED_SOURCES_PER_FREE_USER = 3;

/**
 * The number of liked sources a user can see.
 */
export const AVAILABLE_LIKED_SOURCES_PER_FREE_USER = 3;

/**
 * The  number of letters that a user can listen using reading player
 * @abstract if the value is -1 it should always return true
 */
export const READING_PLAYER_MAX_FREE_NUMBER_OF_LETTERS = -1;

/**
 * Used to determine if we allow the user to create a new stash
 * and if we display the pro icon in the create stash button.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 * @stashesCount - the number of stashes for the user
 */
export const canUserCreateNewStash = ({
  subscriptionType,
  stashesCount,
}: {
  subscriptionType?: UserSubscriptionType;
  stashesCount: number;
}) => {
  switch (subscriptionType) {
    case UserSubscriptionType.Free:
    case UserSubscriptionType.Legacy:
      return stashesCount < STASHES_NUMBER_PER_FREE_USER;
    case UserSubscriptionType.Premium:
      return true;
    default:
      return false;
  }
};

/**
 * Used to determine if we allow the user to sort his saved ideas
 * and if we display the pro icon next to the sort button.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 */
export const canUserSortIdeas = ({
  subscriptionType,
}: {
  subscriptionType?: UserSubscriptionType;
}) => {
  return subscriptionType === UserSubscriptionType.Premium;
};

/**
 * Used to determine if we allow the user to filter published or saved
 * ideas by hashtag.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 */
export const canUserFilterByHashtag = ({
  subscriptionType,
}: {
  subscriptionType?: UserSubscriptionType;
}) => {
  return subscriptionType === UserSubscriptionType.Premium;
};

/**
 * Used to determine if we allow the user to see all saved ideas.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 */
export const canUserSeeLikedIdeas = ({
  subscriptionType,
}: {
  subscriptionType?: UserSubscriptionType;
}) => {
  return subscriptionType === UserSubscriptionType.Premium;
};

/**
 * Used to determine if we allow the user to see all saved ideas.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 */
export const canUserSeeStashedIdeas = ({
  subscriptionType,
}: {
  subscriptionType?: UserSubscriptionType;
}) => {
  return subscriptionType === UserSubscriptionType.Premium;
};

/**
 * Used to determine if we allow the user to see all saved ideas.
 *
 * @subscriptionType - the type of payment: free | premium | legacy
 */
export const canUserSaveIdeas = ({
  subscriptionType,
  savedIdeaCount,
}: {
  subscriptionType?: UserSubscriptionType;
  savedIdeaCount: number;
}) => {
  return (
    subscriptionType === UserSubscriptionType.Premium ||
    savedIdeaCount < AVAILABLE_SAVED_IDEAS_PER_FREE_USER
  );
};
