import React, { useMemo, useState } from 'react';
import {
  Box,
  color,
  Dropdown,
  DropdownProps,
  Flex,
  HeartSvg,
  Img,
  PrimaryText,
  spacing,
  Spinner,
  StyleProps,
  typography,
  useColorMode,
} from 'deepstash-ui';
import { Source } from 'types';
import Tabs, { TabData } from 'src/components/tabs/Tabs';
import ReactionScene from './SourceReactionScene';
import styles from './SourceReactionsDropdown.module.scss';
import DropdownFooterInteractWithApp from '../DropdownFooterInteractWithApp';
import scrollbarStyles from 'src/theme/Scrollbar.module.scss';
import useAuth from 'src/providers/hooks/useAuth';
import useNsi from 'src/providers/hooks/useNsi';
import { ALL_REACTIONS } from 'utils/constants';
import { dropdownMenuStyle, numberFormatter } from 'utils/global';

interface SourceReactionsDropdownProps {
  source: Source;
  dropdownProps?: DropdownProps;
}

const SourceReactionsDropdown: React.FC<
  SourceReactionsDropdownProps & StyleProps
> = ({ source, dropdownProps, ...props }) => {
  const { colorMode } = useColorMode();
  const { isLoggedIn } = useAuth();
  const { nsiDispatch } = useNsi();

  const allReactions = useMemo(() => Object.values(ALL_REACTIONS), []);
  const [tabSelected, onTabSelected] = useState(0);
  const [sourceReactions, setSourceReactions] =
    useState<Record<number, number>>();

  const onPressDropdownButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoggedIn) {
      e.stopPropagation();
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
    }
  };

  const renderDropdownButton = () => (
    <Flex
      alignItems="center"
      position="relative"
      _hover={{ cursor: 'pointer' }}
      onClick={onPressDropdownButton}
      {...props}
    >
      {/* <Flex
            mr={`-${((source?.topReactions?.length ?? 0) - 1) * 8}px`}
            align="center"
          >
            {source?.topReactions?.map((reaction, index) => (
              <Img
                key={`reaction-${reaction.reactionId}-${index}`}
                width={index === 0 ? '36px' : '24px'}
                height={index === 0 ? '36px' : '24px'}
                position="relative"
                left={`-${index * 8}px`}
                src={ALL_REACTIONS[reaction.reactionId]?.url}
                zIndex={source?.topReactions?.length - index}
                border={index === 0 ? '2px solid' : undefined}
                borderColor={color[colorMode].surface}
                borderRadius="100px"
              />
            ))}
          </Flex> */}
      <HeartSvg m={spacing.toRem(8)} color={color[colorMode].text} />
      {source.totalReactions > 0 && (
        <Flex
          position="absolute"
          w="100%"
          justifyContent="center"
          alignItems="center"
          top={spacing.toRem(-12)}
        >
          <PrimaryText
            color={color[colorMode].text}
            {...typography.primaryTextFonts.small.semiBold}
          >
            {numberFormatter(source.totalReactions)}
          </PrimaryText>
        </Flex>
      )}
    </Flex>
  );

  const renderTopReactionsTabs = useMemo(() => {
    return (
      Object.keys(sourceReactions ?? {})
        //Sort the keys descending by the number of reacts
        .sort(
          (a, b) =>
            (sourceReactions ?? {})[Number.parseInt(b)] -
            (sourceReactions ?? {})[Number.parseInt(a)],
        )
        .map(key => {
          const reactionId = Number.parseInt(key);
          return {
            tabRenderer: (isSelected: boolean) => (
              <Flex
                px={spacing.XS.rem}
                pb={spacing.XS.rem}
                borderBottom={
                  isSelected
                    ? `2px solid ${color[colorMode].primary.default}`
                    : 'unset'
                }
              >
                <Img
                  width="20px"
                  height="20px"
                  src={ALL_REACTIONS[reactionId]?.url}
                />
                <PrimaryText
                  color={
                    isSelected
                      ? color[colorMode].primary.default
                      : color[colorMode].text
                  }
                  ml={spacing.XXS.rem}
                >
                  {sourceReactions?.[reactionId]}
                </PrimaryText>
              </Flex>
            ),
            scene: (
              <ReactionScene
                sourceId={source.id}
                reactionId={reactionId as unknown as number}
                allReactions={allReactions}
              />
            ),
          };
        })
    );
  }, [source, sourceReactions, allReactions, colorMode]);

  const tabsData: TabData[] = useMemo(() => {
    return [
      {
        tabRenderer: (isSelected: boolean) => (
          <Flex
            px={spacing.XS.rem}
            borderBottom={
              isSelected
                ? `2px solid ${color[colorMode].primary.default}`
                : 'unset'
            }
            pb={spacing.XS.rem}
          >
            <PrimaryText
              color={
                isSelected
                  ? color[colorMode].primary.default
                  : color[colorMode].text
              }
            >
              All {source.totalReactions}
            </PrimaryText>
          </Flex>
        ),
        scene: (
          <ReactionScene
            sourceId={source.id}
            reactionId={-1} // case for retrieving all reactions
            allReactions={allReactions}
            setSourceReactions={setSourceReactions}
          />
        ),
      },
      ...renderTopReactionsTabs,
    ];
  }, [renderTopReactionsTabs, source]);

  const renderDropdownContent = () => {
    if (source.totalReactions > 0 && !sourceReactions) {
      //Show a spinner until we get the reaction data
      //We still need to render the tabs invisible for the API call to be made
      return (
        <Flex
          height={spacing.toRem(200)}
          justifyContent="center"
          alignItems="center"
          width={{ base: spacing.toRem(300), lg: spacing.toRem(340) }}
        >
          <Spinner color={color[colorMode].primary.default} />
          <Box
            width={{ base: spacing.toRem(300), lg: spacing.toRem(340) }}
            height={spacing.toRem(200)}
            display="none"
          >
            <Tabs
              onTabSelected={index => onTabSelected(index)}
              selectedTab={tabSelected}
              tabs={tabsData}
              cursor="pointer"
              tabsClassName={`${styles.reaction_tabs} ${
                colorMode === 'light'
                  ? styles.reaction_tabs_light_background
                  : styles.reaction_tabs_dark_background
              }`}
            />
          </Box>
        </Flex>
      );
    } else if (source.totalReactions === 0) {
      return (
        <Flex
          alignItems="center"
          flexDir="column"
          color={color[colorMode].text}
        >
          <Img
            my={spacing.M.rem}
            width={{ base: spacing.toRem(300), lg: spacing.toRem(320) }}
            height={spacing.toRem(200)}
            src={`https://static.deepstash.com/illustrations/search_${colorMode}.svg`}
            alt="Privacy Policy"
          />
          <PrimaryText size="m">No one reacted yet</PrimaryText>
        </Flex>
      );
    } else {
      return (
        <Tabs
          onTabSelected={index => onTabSelected(index)}
          selectedTab={tabSelected}
          tabs={tabsData}
          cursor="pointer"
          tabsClassName={`${styles.reaction_tabs} ${
            colorMode === 'light'
              ? styles.reaction_tabs_light_background
              : styles.reaction_tabs_dark_background
          }`}
        />
      );
    }
  };

  return (
    <Dropdown
      dropdownButtonRenderer={renderDropdownButton}
      wrapperButtonStyle={{
        _hover: undefined,
        _active: undefined,
        borderRadius: 0,
        bgColor: 'unset',
      }}
      menuStyle={dropdownMenuStyle}
      wrapperStyle={{ padding: 0, zIndex: 11 }}
      dropdownPositionX="right"
      precomputeDimensions={{
        precomputeDimensionsRender: false,
        expectedHeight: 320,
        expectedWidth: 340,
      }}
      {...dropdownProps}
    >
      {() =>
        isLoggedIn ? (
          <Box
            width="calc(100% + 1rem)"
            ml="-0.5rem"
            height="calc(100% + 1rem)"
            mb="-0.5rem"
          >
            <Box
              backgroundColor={color[colorMode].surface}
              borderRadius={spacing.M.rem}
              height={spacing.toRem(320)}
              mr={spacing.XS.rem}
              width="100%"
              p={spacing.XS.rem}
              overflowX="hidden"
              overflowY="scroll"
              border="none"
              onClick={e => e.stopPropagation()}
              zIndex={210}
              className={
                colorMode === 'light'
                  ? scrollbarStyles.scrollVerticalLightSurface
                  : scrollbarStyles.scrollVerticalDarkSurface
              }
            >
              {renderDropdownContent()}
            </Box>

            <DropdownFooterInteractWithApp description="Join the conversation in the Deepstash app." />
          </Box>
        ) : null
      }
    </Dropdown>
  );
};

export default SourceReactionsDropdown;
