/**
 * calculates the difference between a timestamp given and the current timestamp
 * returns a string in minutes | hours | days | weeks
 * @param timestamp
 */
export const diffTimeSinceTimestamp = (
  timestamp: string,
  defaultLessThanOneMinuteString?: string,
) => {
  const timestampNow = new Date(new Date().toISOString().slice(0, -1));
  const createdAt = new Date(timestamp);
  let timeDifference: number =
    (timestampNow.getTime() - createdAt.getTime()) / 1000;
  let tag: string;

  if (timeDifference >= 60) {
    timeDifference = timeDifference / 60;
    tag = ' m';
    if (timeDifference >= 60) {
      timeDifference = timeDifference / 60;
      tag = ' h';
      if (timeDifference >= 24) {
        timeDifference = timeDifference / 24;
        tag = ' d';
        if (timeDifference >= 7) {
          timeDifference = timeDifference / 7;
          tag = ' w';
        }
      }
    }
  } else {
    return defaultLessThanOneMinuteString ?? '<1m';
  }
  return Math.floor(timeDifference).toString() + tag;
};

/**
 * Get 7th day from today, formatted as 'Month Day'
 * @example May 10
 */
export const getSeventhDateFromToday = () => {
  const today = new Date();
  const seventhDay = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const seventhDayFormatted = seventhDay.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });

  return seventhDayFormatted;
};

/**
 * Formats the given number of seconds into a string representation of minutes.
 * @param seconds The number of seconds to format.
 * @returns A string formatted as "<minutes> mins".
 */
export const formatSecondsToNearestMinute = (seconds: number) => {
  // Calculate minutes, rounding to the nearest minute
  const minutes = Math.round(seconds / 60);
  return `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
};
