import {
  color,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import Chip from 'src/components/chips/Chip';
import React, { useCallback } from 'react';
import { ChipData } from 'types/models';
import SelectableChipArray from '../chips/SelectableChipArray';
import { UNSORTED_TOPICS } from 'utils/metatopics.constants';

interface HashtagChipCarouselProps {
  /**
   * Hashtags showed in the carousel
   */
  hashtags: ChipData[];
  /**
   * Extra element, will be the last element of the carousel
   */
  footer?: React.ReactElement;
  numberOfChipsThreshold?: number;
  renderItem?: ({
    item,
    index,
  }: {
    item: ChipData;
    index: number;
  }) => React.ReactChild;
}

const HashtagChipCarousel: React.FC<HashtagChipCarouselProps & StyleProps> = ({
  hashtags,
  footer,
  numberOfChipsThreshold,
  renderItem,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const defaultRenderer = useCallback(
    ({ item, index }: { item: ChipData; index: number }) => {
      return (
        <>
          <Chip
            key={`list-hashtag-${item.name}-${index}`}
            content={UNSORTED_TOPICS[item.name]?.title ?? item.name}
            href={`/topic/${item.name}`}
            counter={item.counter}
            display="inline-flex"
            padding={0}
            backgroundColor={'transparent'}
            hasHoverEffect={false}
            color={color[colorMode].text}
            opacity={0.4}
            textSize="m"
            borderRadius={0}
            _hover={{ textDecoration: 'underline' }}
            m={0}
          />
          {index <
            Math.max(hashtags.length - 1, numberOfChipsThreshold ?? 0) && (
            <PrimaryText color={color[colorMode].text} opacity={0.4} m={0}>
              ·
            </PrimaryText>
          )}
        </>
      );
    },
    [colorMode],
  );

  return (
    <SelectableChipArray
      chipData={hashtags}
      renderItem={renderItem ?? defaultRenderer}
      footer={footer}
      numberOfChipsThreshold={numberOfChipsThreshold}
      gridGap={spacing.XS.rem}
      {...props}
    />
  );
};

export default HashtagChipCarousel;
