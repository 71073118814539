// SAVED IDEAS HEIGHTS
export const HEADER_SPACE = 80;

// ------------------------

// LAYOUT WIDTHS
const SIDE_MENU_WIDTH = 300;
export const LEFT_SIDE_WIDTH = SIDE_MENU_WIDTH;
export const RIGHT_SIDE_WIDTH = SIDE_MENU_WIDTH;
export const FEED_WIDTH = 735;
const CONTENT_WIDTH = 870;
export const SAVED_IDEAS_WIDTH = CONTENT_WIDTH;
export const ARTICLE_WIDTH = CONTENT_WIDTH;
export const DROPDOWN_WIDTH = 280;
export const LOGGED_OUT_AD_WIDTH = 320;

// ------------------------

// COMPONENT SIZES

// FEED
export const CAROUSEL_WIDTH = 870;
export const CAROUSEL_ITEM_WIDTH = 400;
export const CAROUSEL_ITEM_HEIGHT = 400;

// KNOWLEDGE CARDS
export const KNOWLEDGE_CARD_WIDTH = 270;
export const KNOWLEDGE_CARD_HEIGHT = 270;

// INSIGHT CARDS
export const SMALL_INSIGHT_CARD_WIDTH = 130;
export const SMALL_INSIGHT_CARD_HEIGHT = 125;
export const INSIGHT_PREVIEW_WIDTH_S = 164;
export const INSIGHT_PREVIEW_WIDTH_L = 265;
export const INSIGHT_PREVIEW_HEiGHT_S = 129;
export const INSIGHT_PREVIEW_HEiGHT_L = 350;

// SAVED IDEAS
export const IDEA_WIDTH = 415;
export const IDEA_TITLE_INPUT_WIDTH = IDEA_WIDTH - 80;
export const PREVIEW_IDEA_WIDTH = 279;
export const PREVIEW_IDEA_HEIGHT = 279;
const HEADER_OVERFLOW = 30;
const HEADER_OVERFLOW_SCROLLING = 15;
export const HEADER_WIDTH = CAROUSEL_WIDTH;
export const HEADER_WIDTH_SCROLLING =
  CAROUSEL_WIDTH + 2 * HEADER_OVERFLOW_SCROLLING;
export const HEADER_WIDTH_SHORT = FEED_WIDTH + 2 * HEADER_OVERFLOW;
export const HEADER_WIDTH_SHORT_SCROLLING = FEED_WIDTH + 2 * HEADER_OVERFLOW;

// TOAST
export const TOAST_WIDTH = 600;

// ------------------------

// STYLING
export const BORDER_RADIUS_S = '0.5rem';
export const BORDER_RADIUS_M = '1rem';
export const BORDER_RADIUS_L = '1.25rem';
export const BORDER_RADIUS_MAX = 1000;

export const MARGIN_S = 10;
export const MARGIN_M = 15;
export const MARGIN_L = 25;

// PADDING
export const CONTENT_PADDING_S = 10;
export const CONTENT_PADDING_M = 15;
export const CONTENT_PADDING_L = 20;
export const PAGE_PADDING = 25;

// ------------------------

// MISC
export const CURTAIN_THRESHOLD = 1500;
export const TITLE_MAX_LEN = 90;
