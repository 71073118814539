import {
  CollectionApiResponse,
  CollectionProgressApiResponse,
} from 'api/api.types';
import {
  CollectionData,
  CollectionProgress,
  SourceCollectionProgress,
} from 'types/models';
import { normalizeSource } from './source';
import { Normalizer } from './types';

export const normalizeCollectionProgress: Normalizer<
  CollectionProgressApiResponse | undefined,
  CollectionProgress
> = collectionProgress => {
  let totalReadIdeas = 0;
  let completed = false;
  const sourcesProgress: Record<number, SourceCollectionProgress> = {};
  if (collectionProgress) {
    totalReadIdeas = collectionProgress.total_blocks_read ?? 0;
    completed = collectionProgress.completed ?? false;
    if (collectionProgress.article_progress) {
      Object.entries(collectionProgress.article_progress).forEach(
        sourceProgressApiPair => {
          sourcesProgress[parseInt(sourceProgressApiPair[0])] = {
            readIdeas: sourceProgressApiPair[1].read_blocks,
            completed: sourceProgressApiPair[1].completed,
          };
        },
      );
    }
  }

  return {
    totalReadIdeas,
    completed,
    sourcesProgress,
  };
};

export const normalizeCollection: Normalizer<
  CollectionApiResponse,
  CollectionData
> = collection => {
  return {
    id: collection.id,
    sources: collection?.articles
      ? collection.articles.map(article => normalizeSource(article))
      : [],
    description: collection.description,
    emoji: collection.emoji ?? '',
    hashtags: collection?.hashtags ?? [],
    imageUrl: collection.image_compressed ?? collection.image,
    imageBlurhash: collection.image_blurhash,
    progress: normalizeCollectionProgress(collection.my_progress),
    name: collection.name,
    articlesCount: collection.total_article_sources ?? 0,
    booksCount: collection.total_book_sources ?? 0,
    ideaCount: collection.total_blocks ?? 0,
    podcastCount: collection.total_podcast_sources ?? 0,
    independentsCount: collection.total_selfpost_sources ?? 0,
    videosCount: collection.total_video_sources ?? 0,
    imagePreview: collection.image_preview,
  };
};

export const normalizeCollections = (
  collectionsApiResponse: CollectionApiResponse[],
) => {
  const collections = collectionsApiResponse.map(collection => {
    return normalizeCollection(collection);
  });
  return collections;
};
