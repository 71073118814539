import { Button } from 'deepstash-ui';
import React from 'react';

interface ButtonProps {
  hoverBackgroundColor?: string;
  backgroundColor?: string;
  spacing?: string;
  click?: React.MouseEventHandler<HTMLButtonElement>;
  dataTestId?: string;
}

//To be deleted/moved to ds-ui/revamped
const NavButton: React.FC<ButtonProps> = props => {
  return (
    <div style={{ padding: props.spacing || '0 16px' }}>
      <Button
        data-testid={props.dataTestId}
        textDecoration="none"
        _hover={{
          textDecoration: 'none',
          backgroundColor: props.hoverBackgroundColor
            ? props.hoverBackgroundColor
            : 'none',
        }}
        _focus={{
          outline: 'none',
        }}
        height="auto"
        width="100%"
        paddingX="12px"
        paddingY="10px"
        borderRadius="10px"
        outline="none"
        backgroundColor={props.backgroundColor ? props.backgroundColor : 'none'}
        onClick={
          props.click
            ? props.click
            : () => {
                return;
              }
        }
      >
        {props.children}
      </Button>
    </div>
  );
};

export default NavButton;
