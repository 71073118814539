import {
  Box,
  color,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import Chip from 'src/components/chips/Chip';
import HashtagChipCarousel from 'src/components/hashtag/HashtagChipCarousel';
import SourceAuthor from 'src/components/social/SourceAuthor';
import SourceTitleByType from 'src/page-components/source/components/header/SourceTitleByType';
import { SourceCollapsedProps } from './SourceCollapsed';

const SourceCollapsedLarge: React.FC<SourceCollapsedProps & StyleProps> = ({
  source,
  preload,
  showContext,
  showTopics,
  titleOverride,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  if (!source) return null;

  /**
   * If the id is -1 this means the source is coming from KnowledgeLibrary
   * so we won't show the UserSummary in that case
   */
  const hasUserData = source.userData.id > 0;
  /**
   * If the ideas.length is 0 this means the source is coming from KnowledgeLibrary
   * since we shouldn't have sources with 0 ideas
   * so we won't show the IdeasCount in that case
   */

  return (
    <Box zIndex={2} {...props}>
      {hasUserData && (
        <SourceAuthor
          user={source.userData}
          ml={0}
          mb={spacing.M.rem}
          color={color[colorMode].textSecondary}
          preload={preload}
        />
      )}
      <Flex flexDirection="column" gridGap={spacing.XS.rem} mt={spacing.XS.rem}>
        {source?.context && showContext && (
          <PrimaryText
            size="m"
            color={color[colorMode].text}
            overflowWrap="break-word"
            lineHeight="1.25em"
            noOfLines={2}
          >
            {source.context}
          </PrimaryText>
        )}
        <SourceTitleByType
          source={source}
          size={isMobileView ? 'sm' : 'md'}
          withLink={true}
          m={0}
          preload={preload}
          titleOverride={titleOverride}
        />
        {showTopics && source.ideas[0]?.hashtags && (
          <HashtagChipCarousel
            overflow="visible"
            hashtags={source.ideas[0]?.hashtags.map(hashtag => ({
              name: hashtag,
            }))}
            gridGap={0}
            renderItem={({ item, index }) => (
              <Chip
                key={`list-hashtag-${item.name}-${index}`}
                content={'#' + item.name}
                href={`/topic/${item.name}`}
                counter={item.counter}
                display="inline-flex"
                paddingY={0}
                paddingLeft={0}
                paddingRight={spacing.XS.rem}
                backgroundColor={'transparent'}
                color={color[colorMode].textDisabled}
                hasHoverEffect={false}
                m={0}
              />
            )}
          />
        )}
      </Flex>
    </Box>
  );
};

export default SourceCollapsedLarge;
