import {
  ButtonProps,
  color,
  PrimaryButton,
  PrimaryText,
  useColorMode,
} from 'deepstash-ui';
import React, { useMemo } from 'react';

interface FollowDummyButtonProps {
  isFollowed: boolean;
  onClickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isDisabled: boolean;
  followingBackgroundColor?: string;
  title?: string;
}

export const FollowDummyButton: React.FC<
  FollowDummyButtonProps & ButtonProps
> = ({
  isDisabled,
  isFollowed,
  onClickHandler,
  size,
  followingBackgroundColor,
  title,
  id,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const followText = useMemo(() => {
    if (title) {
      return title;
    }
    return isFollowed ? 'Following' : 'Follow';
  }, [isFollowed, title]);

  followingBackgroundColor = followingBackgroundColor ?? color[colorMode].top;
  return (
    <PrimaryButton
      colorMode={colorMode}
      size={size}
      bgColor={
        isFollowed ? followingBackgroundColor : color[colorMode].primary.default
      }
      color={
        isFollowed ? color[colorMode].primary.default : color[colorMode].surface
      }
      borderColor={color[colorMode].primary.default}
      _active={undefined}
      onClick={onClickHandler}
      disabled={isDisabled}
      id={id}
      {...props}
    >
      <PrimaryText
        type="bold"
        w="max-content"
        size={size === 'lg' ? 'l' : 's'}
        id={id ? `${id}-text` : undefined}
      >
        {followText}
      </PrimaryText>
    </PrimaryButton>
  );
};
