import { getShortenedLink } from 'api/Article';
import {
  Box,
  color,
  CopySvg,
  Dropdown,
  FlagReportSvg,
  Flex,
  PrimaryText,
  spacing,
  ThreeDotsHorizontalSvg,
  UrlSvg,
  useColorMode,
  useDisclosure,
  useIsMobileView,
  useToast,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import useProfile from 'providers/hooks/useProfile';
import useSource from 'providers/hooks/useSource';
import React from 'react';
import NavButton from 'src/components/buttons/NavButton';
import useAuth from 'src/providers/hooks/useAuth';
import { Analytics, Events } from 'src/services/analytics';
import { EntityReportType } from 'types';
import { TOAST_TIME } from 'utils/constants';
import { toastStrings } from 'utils/strings';

interface SourceDropdownMenuProps {
  ownerId: number;
  type: EntityReportType;
  dropdownYPosition?: 'top' | 'bottom';
}

const ReportModal = dynamic(
  () => import('src/components/modals/report-modal/ReportModal'),
);

export const SourceDropdownMenu: React.FC<SourceDropdownMenuProps> = ({
  ownerId,
  type,
  dropdownYPosition = 'top',
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  const { isLoggedIn } = useAuth();
  const { source } = useSource();
  const { profile } = useProfile();
  const toast = useToast();

  const {
    isOpen: isReportModalOpen,
    onOpen: onReportModalOpen,
    onClose: onReportModalClose,
  } = useDisclosure();

  if (!source) return null;

  return (
    <>
      <Dropdown
        dropdownPositionY={dropdownYPosition}
        wrapperButtonStyle={{
          _hover: {
            cursor: 'pointer',
          },
        }}
        dropdownButtonRenderer={() => (
          <ThreeDotsHorizontalSvg
            m={spacing.toRem(8)}
            mb={spacing.toRem(3.5)}
          />
        )}
        menuStyle={{
          backgroundColor: color[colorMode].top,
          border: 'none',
          borderRadius: '10px',
          zIndex: 10,
          width: '14rem',
          boxShadow:
            colorMode === 'light'
              ? '0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)'
              : 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px;',
        }}
      >
        {onClose =>
          isLoggedIn || !isMobileView ? (
            <>
              {profile?.id !== source?.userData.id && (
                <NavButton
                  backgroundColor={color[colorMode].top}
                  hoverBackgroundColor={color[colorMode].top}
                  spacing="0"
                  click={(ev: React.MouseEvent) => {
                    ev.stopPropagation();
                    onClose();
                    if (onReportModalOpen) {
                      onReportModalOpen();
                      Analytics.logEvent({
                        eventName: Events.modals.openReportModal,
                        properties: {
                          type: 'stash',
                        },
                        platforms: ['amplitude'],
                      });
                    }
                  }}
                >
                  <Flex width="100%" alignItems="center">
                    <FlagReportSvg color={color.failure.default} />
                    <PrimaryText
                      paddingLeft="20px"
                      color={color.failure.default}
                      fontSize="1rem"
                      lineHeight={1.2}
                      fontWeight={600}
                    >
                      Report
                    </PrimaryText>
                  </Flex>
                </NavButton>
              )}
              <NavButton
                backgroundColor={color[colorMode].top}
                hoverBackgroundColor={color[colorMode].top}
                spacing="0"
                click={(ev: React.MouseEvent) => {
                  ev.stopPropagation();
                  onClose();
                  window.open(
                    source?.url,
                    '_blank',
                    'noopener noreferrer nofollow',
                  );
                }}
              >
                <Flex width="100%" alignItems="center">
                  <UrlSvg color={color[colorMode].text} />
                  <PrimaryText
                    paddingLeft="20px"
                    color={color[colorMode].text}
                    fontSize="1rem"
                    lineHeight={1.2}
                    fontWeight={600}
                  >
                    Visit Original
                  </PrimaryText>
                </Flex>
              </NavButton>
              {profile?.userType === 'developer' && (
                <NavButton
                  backgroundColor={color[colorMode].top}
                  hoverBackgroundColor={color[colorMode].top}
                  spacing="0"
                  click={() => {
                    getShortenedLink({
                      source,
                      socialImage: source.image ?? '',
                      socialTitle: source.title,
                      extraParams: {
                        show: 'all',
                      },
                    }).then(res => {
                      toast({
                        title: toastStrings.linkCopied,
                        status: 'success',
                        duration: TOAST_TIME,
                        isClosable: true,
                      });
                      navigator.clipboard.writeText(res.shortLink);
                    });
                  }}
                >
                  <Flex width="100%" alignItems="center">
                    <CopySvg color={color[colorMode].text} />
                    <PrimaryText
                      paddingLeft="20px"
                      color={color[colorMode].text}
                      fontSize="1rem"
                      lineHeight={1.2}
                      fontWeight={600}
                    >
                      Copy Social Link
                    </PrimaryText>
                  </Flex>
                </NavButton>
              )}
            </>
          ) : (
            <Box />
          )
        }
      </Dropdown>

      {isReportModalOpen && (
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={onReportModalClose}
          id={source.id}
          type={type}
          resourceOwner={ownerId}
        />
      )}
    </>
  );
};

export default SourceDropdownMenu;
