import React from 'react';
import {
  Box,
  ChatSvg,
  color,
  Dropdown,
  DropdownProps,
  Flex,
  Img,
  PrimaryText,
  spacing,
  StyleProps,
  typography,
  useColorMode,
} from 'deepstash-ui';
import { Source } from 'types';
import DropdownFooterInteractWithApp from '../DropdownFooterInteractWithApp';
import MemoSourceCommentsDropdownContainer from './MemoSourceCommentsDropdownContainer';
import styles from 'src/theme/Scrollbar.module.scss';
import useAuth from 'src/providers/hooks/useAuth';
import useNsi from 'src/providers/hooks/useNsi';
import { dropdownMenuStyle, numberFormatter } from 'utils/global';

interface SourceCommentsDropdownProps {
  source: Source;
  dropdownProps?: DropdownProps;
}

const SourceCommentsDropdown: React.FC<
  SourceCommentsDropdownProps & StyleProps
> = ({ source, dropdownProps, ...props }) => {
  const { colorMode } = useColorMode();

  const { isLoggedIn } = useAuth();
  const { nsiDispatch } = useNsi();

  const onPressDropdownButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoggedIn) {
      e.stopPropagation();
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
    }
  };

  const renderDropdownButton = () => (
    <Flex
      alignItems="center"
      position="relative"
      _hover={{ cursor: 'pointer' }}
      onClick={onPressDropdownButton}
      {...props}
    >
      <ChatSvg color={color[colorMode].text} />
      {source.totalComments > 0 && (
        <Flex
          position="absolute"
          w="100%"
          justifyContent="center"
          alignItems="center"
          top={spacing.toRem(-20)}
        >
          <PrimaryText
            color={color[colorMode].text}
            {...typography.primaryTextFonts.small.semiBold}
          >
            {numberFormatter(source.totalComments)}
          </PrimaryText>
        </Flex>
      )}
    </Flex>
  );

  return (
    <Dropdown
      dropdownButtonRenderer={renderDropdownButton}
      wrapperButtonStyle={{
        _hover: undefined,
        _active: undefined,
        borderRadius: 0,
        bgColor: 'unset',
      }}
      menuStyle={dropdownMenuStyle}
      wrapperStyle={{ padding: 0, zIndex: 11 }}
      precomputeDimensions={{
        precomputeDimensionsRender: false,
        expectedHeight: 320,
        expectedWidth: 340,
      }}
      {...dropdownProps}
    >
      {() =>
        isLoggedIn ? (
          <Box
            width="calc(100% + 1rem)"
            ml="-0.5rem"
            height="calc(100% + 1rem)"
            mb="-0.5rem"
          >
            <Box
              backgroundColor={color[colorMode].surface}
              borderRadius={spacing.M.rem}
              height={spacing.toRem(320)}
              mr={spacing.XS.rem}
              width="100%"
              p={spacing.XS.rem}
              overflowX="hidden"
              overflowY="auto"
              border="none"
              onClick={e => e.stopPropagation()}
              zIndex={210}
              className={
                colorMode === 'light'
                  ? styles.scrollVerticalLightSurface
                  : styles.scrollVerticalDarkSurface
              }
            >
              {source.totalComments === 0 ? (
                <Flex
                  alignItems="center"
                  flexDir="column"
                  color={color[colorMode].text}
                >
                  <Img
                    my={spacing.M.rem}
                    width={{ base: spacing.toRem(300), lg: spacing.toRem(320) }}
                    height={spacing.toRem(200)}
                    src={`https://static.deepstash.com/illustrations/search_${colorMode}.svg`}
                    alt="Privacy Policy"
                  />
                  <PrimaryText size="m" color={color[colorMode].text}>
                    No one commented yet
                  </PrimaryText>
                </Flex>
              ) : (
                <MemoSourceCommentsDropdownContainer sourceId={source.id} />
              )}
            </Box>
            <DropdownFooterInteractWithApp description="Comment with the Deepstash mobile app." />
          </Box>
        ) : null
      }
    </Dropdown>
  );
};

export default SourceCommentsDropdown;
