import React from 'react';
import {
  useColorMode,
  color,
  Flex,
  spacing,
  Dropdown,
  PrimaryText,
  StyleProps,
} from 'deepstash-ui';
import { ChipData } from 'types';

const NUMBER_OF_CHIPS_THRESHOLD = 10;

interface SelectableChipArrayProps {
  chipData: ChipData[];
  renderItem: ({
    item,
    index,
  }: {
    item: ChipData;
    index: number;
  }) => React.ReactChild;
  /**
   * @default renderItem is the default value
   */
  renderDropdownItem?: ({
    item,
    index,
  }: {
    item: ChipData;
    index?: number;
  }) => React.ReactChild;
  renderDropdownButtonRenderer?: () => React.ReactElement;
  /**
   * element at the final of the array threshold
   */
  footer?: React.ReactElement;
  /**
   * number of chips to render
   * @default 10
   */
  numberOfChipsThreshold?: number;
  /**
   * Whether we should render all chips on one scrollable line
   * @default false
   */
  hasHorizontalScroll?: boolean;
}

const SelectableChipArray: React.FC<SelectableChipArrayProps & StyleProps> = ({
  chipData,
  renderItem,
  renderDropdownItem = renderItem,
  footer,
  hasHorizontalScroll,
  numberOfChipsThreshold = NUMBER_OF_CHIPS_THRESHOLD,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexWrap={hasHorizontalScroll ? 'nowrap' : 'wrap'}
      alignItems="center"
      overflowX={hasHorizontalScroll ? 'scroll' : 'hidden'}
      overflowY="hidden"
      {...props}
    >
      {(hasHorizontalScroll
        ? chipData
        : chipData.slice(0, numberOfChipsThreshold)
      ).map((item, index) => renderItem({ item, index }))}

      {!hasHorizontalScroll && chipData.length > numberOfChipsThreshold && (
        <Dropdown
          dropdownButtonRenderer={() => (
            <Flex
              py={spacing.XS.rem}
              px={spacing.M.rem}
              borderRadius={spacing.L.rem}
              backgroundColor={color[colorMode].top}
            >
              <PrimaryText color={color[colorMode].textSecondary}>
                {`+${chipData.length - numberOfChipsThreshold}`}
              </PrimaryText>
            </Flex>
          )}
          wrapperButtonStyle={{
            _hover: { cursor: 'pointer' },
            _active: undefined,
            _focus: undefined,
            _pressed: undefined,
            backgroundColor: 'unset',
          }}
          menuStyle={{
            boxShadow:
              '0px 10px 32px -4px rgba(0,0,0, 0.2), 0px 6px 14px -6px rgba(0,0,0, 0.12)',
          }}
        >
          <Flex
            maxH="10rem"
            flexDir="column"
            p={spacing.XS.rem}
            alignItems="flex-start"
          >
            {chipData
              .slice(numberOfChipsThreshold)
              .map((item, index) => renderDropdownItem({ item, index }))}
          </Flex>
        </Dropdown>
      )}
      {footer}
    </Flex>
  );
};

export default SelectableChipArray;
