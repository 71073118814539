import useActivity from 'src/providers/hooks/useActivity';
import useAuth from 'src/providers/hooks/useAuth';
import { useMemo } from 'react';

/**
 * TODO needs to be moved in subfolder
 * @param userId
 */
export const useIsUserFollowed = (userId: number) => {
  const { activity } = useActivity();
  const { isLoggedIn } = useAuth();

  const isUserFollowed = useMemo(() => {
    if (!activity) return false;

    return isLoggedIn && activity?.following.includes(userId);
  }, [activity?.following, isLoggedIn]);

  return { isUserFollowed };
};
