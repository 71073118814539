import { UserCommentsApiResponse } from 'api/api.types';
import { Box, color, Flex, spacing, Spinner, useColorMode } from 'deepstash-ui';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import React, { useMemo, useRef } from 'react';
import CommentsUserListProps from 'src/components/comments/CommentsUserListProps';
import { UserComment } from 'types/models';
import { normalizeComments } from 'utils/normalizers/source';

export interface SourceCommentsDropdownContainerProps {
  sourceId: number;
}

const SourceCommentsDropdownContainer: React.FC<
  SourceCommentsDropdownContainerProps
> = ({ sourceId }) => {
  const { colorMode } = useColorMode();

  const initialUrl = useMemo(() => {
    return `/article/${sourceId}/comments/`;
  }, [sourceId]);

  const target = useRef<HTMLDivElement>(null);

  const { data, isFetching } = useInfiniteScroll<UserCommentsApiResponse>({
    firstPageUrl: initialUrl,
    queryKey: ['commentsDropdown', sourceId],
    getKey: (_index, previousPageData) => {
      //reached the end
      if (previousPageData && previousPageData.objects.length === 0)
        return undefined;

      return previousPageData.meta.next?.replace('/api/v2/', '');
    },
    target,
  });

  const comments: UserComment[] = useMemo(() => {
    return data.map(comments => comments.objects.map(normalizeComments)).flat();
  }, [data]);

  return (
    <Box>
      <CommentsUserListProps comments={comments} />
      {isFetching && (
        <Flex
          justifyContent="center"
          align="center"
          mt={spacing.XS.rem}
          width={{ base: spacing.toRem(300), lg: spacing.toRem(340) }}
          height={spacing.toRem(200)}
        >
          <Spinner color={color[colorMode].textSecondary} />
        </Flex>
      )}
      <Box h="32px" ref={target} />
    </Box>
  );
};

export default SourceCommentsDropdownContainer;
