import { ButtonProps, useColorMode, color, spacing } from 'deepstash-ui';
import { useIsUserFollowed } from 'hooks/user/useIsUserFollowed';
import useActivity from 'src/providers/hooks/useActivity';
import useProfile from 'src/providers/hooks/useProfile';
import React, { useState } from 'react';
import { FollowDummyButton } from './FollowDummyButton';
import useNsi from 'src/providers/hooks/useNsi';
import useAuth from 'src/providers/hooks/useAuth';
import { Analytics, Events } from 'src/services/analytics';

interface FollowUserButtonProps extends ButtonProps {
  userId: number;
  title?: string;
  onFollowSuccess?: (isFollowing: boolean) => void;
  followingBackgroundColor?: string;
}

const FollowUserButton: React.FC<FollowUserButtonProps> = ({
  userId,
  size,
  title,
  onFollowSuccess,
  followingBackgroundColor,
  ...props
}) => {
  const { profile } = useProfile();
  const { toggleFollowUser } = useActivity();
  const { nsiDispatch } = useNsi();
  const { isLoggedIn } = useAuth();

  const ownId = profile?.id;

  const { isUserFollowed: isFollowed } = useIsUserFollowed(userId);

  const [isDisabled, setIsDisabled] = useState(false);

  const { colorMode } = useColorMode();

  const onRequestFinish = () => {
    setIsDisabled(false);
  };

  const onClickHandler = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDisabled(true);

    if (!isLoggedIn) {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
      setIsDisabled(false);
      return;
    }

    if (isFollowed) {
      Analytics.logEvent({
        eventName: Events.social.unfollowUser,
        properties: {
          targetProfileId: userId,
        },
        platforms: ['amplitude'],
      });
      onFollowSuccess?.(true);
    } else {
      Analytics.logEvent({
        eventName: Events.social.followUser,
        properties: {
          targetProfileId: userId,
        },
        platforms: ['amplitude'],
      });
      onFollowSuccess?.(false);
    }

    toggleFollowUser({
      userId: userId,
      isFollowed: isFollowed,
      onFinish: onRequestFinish,
    });
  };

  if (ownId === userId) return null;

  return (
    <FollowDummyButton
      isDisabled={isDisabled}
      size={size}
      isFollowed={isFollowed}
      onClickHandler={e => onClickHandler(e)}
      ml={{ lg: spacing.S.rem }}
      followingBackgroundColor={
        followingBackgroundColor ?? color[colorMode].surface
      }
      title={title}
      id={`follow-user-button-${userId}`}
      {...props}
    />
  );
};

export default FollowUserButton;
