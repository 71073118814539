import useCreateContextConsumer from './useCreateContextConsumer';
import {
  NsiActivityContext,
  NsiActivityExtra,
  NsiActivityProviderAction,
  NsiActivityProviderContextType,
  NsiActivityProviderState,
} from '../nsi-activity';

const useNsiActivity = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    NsiActivityProviderState,
    NsiActivityProviderAction,
    NsiActivityProviderContextType,
    never,
    never,
    NsiActivityExtra
  >({ context: NsiActivityContext });
  return {
    ...contextConsumer,
    nsiActivityDispatch: dispatch,
  };
};

export default useNsiActivity;
