import React, { useMemo } from 'react';
import StashOptionsMenu from 'src/components/stash-options-menu/StashOptionsMenu';
import useNsi from 'src/providers/hooks/useNsi';
import { Idea, Source } from 'types';
import useActivity from 'src/providers/hooks/useActivity';
import useAuth from 'src/providers/hooks/useAuth';
import {
  color,
  DownArrowSvg,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import { isStashPage, trimStashName } from 'utils/global';
import useRouter from 'hooks/useRouter';
import { Analytics, Events } from 'src/services/analytics';
import useNsiActivity from 'providers/hooks/useNsiActivity';
import { NSI_STASH_ID } from 'providers/activity/ActivityReducerUtils';
import actionStrings from 'utils/strings/actionStrings';

interface StashSourceButtonProps {
  source: Source;
  /**
   * Signal up the tree that this source got stashed
   */
  onSourceStashed?: () => void;
  /**
   * Signal up the tree that this source got unstashed
   */
  onSourceUnstashed?: () => void;
  ideas: Idea[];
  withoutText?: boolean;
}

const StashSourceButton: React.FC<StashSourceButtonProps & StyleProps> = ({
  source,
  ideas,
  onSourceStashed,
  onSourceUnstashed,
  withoutText,
  ...props
}) => {
  const { isLoggedIn } = useAuth();
  const { activity, activityDispatch } = useActivity();
  const { nsiDispatch } = useNsi();
  const router = useRouter();
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();
  const { stash: nsiStashes } = useNsiActivity();

  const currentSelectedStashId = useMemo(
    () =>
      isLoggedIn
        ? // If the user is logged in, find the stash that holds the first idea of the source
          // That stash is the stash of the source
          activity?.stashedIdeas?.find(x => x[0] === ideas?.[0]?.id)?.[1]
        : // The user is not logged in, check if the source is stashed in the NSI provider
        nsiStashes.stashedSources.find(id => id === source.id)
        ? // It's stash. The stash id is th only one possible for NSI
          NSI_STASH_ID
        : // The source is not stashed
          undefined,
    [activity?.stashedIdeas, ideas, nsiStashes.stashedSources],
  );

  //If this is a source the current user published without a stash,
  // it will appear in the stashed idea id, but won't have a stash associated
  const isStashed =
    // Either it's logged in and the source is stashed/published by the user
    // Or it's logged out and the source is stashed as NSI
    (!isLoggedIn || (activity?.stashedArticles ?? []).includes(source.id)) &&
    currentSelectedStashId !== undefined;

  const notSavedCount = useMemo(
    () =>
      (ideas ?? []).filter(block =>
        activity?.stashedIdeasIds?.includes(block.id),
      ).length,
    [activity?.stashedIdeasIds],
  );

  const onStashClick = (id: number) => {
    if (!isLoggedIn || !activity) {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
      return;
    }

    Analytics.logEvent({
      eventName: Events.organising.saveAllIdeas,
      properties: {
        sourceId: source.id || 0,
        destination: 'stash',
        stashId: id !== -1 ? id : undefined,
        ideaCount: source.ideas.length,
        newCount: notSavedCount,
      },
      platforms: ['amplitude'],
    });

    activityDispatch({
      type: 'stash-source',
      payload: {
        ideasIds: ideas.map(idea => idea.id) ?? [],
        source: source,
        stashId: id,
        onSuccess: () => {
          if (!isStashed) {
            onSourceStashed?.();
          }
        },
      },
    });
  };

  const onUnstashClick = () => {
    if (!isLoggedIn || !activity) {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
      return;
    }

    Analytics.logEvent({
      eventName: Events.organising.unsaveAllIdeas,
      properties: {
        sourceId: source.id,
        ideaCount: source?.ideas?.length ?? 0,
      },
      platforms: ['amplitude'],
    });

    activityDispatch({
      type: 'unstash-source ',
      payload: {
        ideasIds: ideas.map(idea => idea.id),
        source,
        onSuccess: () => {
          if (isStashed) {
            onSourceUnstashed?.();
          }
        },
      },
    });
  };

  const currentStashed = useMemo(
    () =>
      activity?.stashes.find(
        stash => stash.id === currentSelectedStashId,
        [currentSelectedStashId],
      ),
    [currentSelectedStashId],
  );

  const getLabel = () => {
    if (isStashPage(router.asPath)) {
      return (
        <PrimaryText
          type="semiBold"
          color={color[colorMode].text}
          ml={spacing.XS.rem}
          id={`stash-source-button-${source.id}-stash-page-label`}
        >
          {isStashed ? `` : actionStrings.saveAll.toUpperCase()}
        </PrimaryText>
      );
    } else {
      return (
        <Flex
          alignItems="center"
          id={`stash-source-button-${source.id}-label-wrapper`}
        >
          <PrimaryText
            type="semiBold"
            color={color[colorMode].text}
            ml={spacing.XS.rem}
            id={`stash-source-button-${source.id}-label`}
          >
            {isStashed
              ? isLoggedIn
                ? `${trimStashName(currentStashed?.name, isMobileView)}`
                : actionStrings.saved.toUpperCase()
              : actionStrings.saveAll.toUpperCase()}
          </PrimaryText>
          {isStashed && (
            <DownArrowSvg
              id={`stash-source-button-${source.id}-icon`}
              ml={spacing.XS.rem}
              boxSize={spacing.L.rem}
            />
          )}
        </Flex>
      );
    }
  };

  return (
    <StashOptionsMenu
      isStashed={isStashed}
      selectedStashId={currentSelectedStashId}
      onStash={onStashClick}
      getLabel={withoutText ? undefined : getLabel}
      onUnstash={onUnstashClick}
      backgroundColor={'transparent'}
      id={`source-stash-button-${source.id}`}
      {...props}
      source={source}
      dropdownPositionX={'left'}
    />
  );
};

export default StashSourceButton;
