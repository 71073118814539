import { color, Flex, spacing, StyleProps, useColorMode } from 'deepstash-ui';
import React, { useEffect, useState } from 'react';
import { TRANSITION_TIME } from 'utils/constants';

interface ProgressBarProps {
  /**
   * The direction of the progress bar.
   * @default 'horizontal'.
   */
  direction?: 'vertical' | 'horizontal';
  /**
   * True if we want a number tag next to the bar
   */
  showTag?: boolean;
  currentValue: number;
  totalValue: number;
  /**
   * If set, the bar will fill to the [currentValue] over [autoplayDuration] miliseconds.
   */
  autoplayDuration?: number;
  /**
   * True if we want the bar to fill between [10, 90]% instead of [0, 100]%
   */
  isPadded?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps & StyleProps> = ({
  direction = 'horizontal',
  currentValue: initialCurrentValue,
  totalValue,
  showTag,
  autoplayDuration,
  isPadded,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const [currentValue, setCurrentValue] = useState(
    autoplayDuration ? 0 : initialCurrentValue,
  );

  useEffect(() => {
    setCurrentValue(initialCurrentValue);
  }, [initialCurrentValue]);

  const getFillInPercentage = () => {
    if (isPadded) {
      return currentValue === 0
        ? 0
        : currentValue === totalValue
        ? 100
        : Math.min(Math.max(currentValue / totalValue, 0.1), 0.9) * 100;
    } else {
      return (currentValue / totalValue) * 100;
    }
  };

  return (
    <Flex position="relative" bgColor={color[colorMode].underground} {...props}>
      <Flex
        borderRadius={props.borderRadius}
        height={
          direction === 'horizontal' ? '100%' : `${getFillInPercentage()}%`
        }
        width={
          direction === 'horizontal' ? `${getFillInPercentage()}%` : '100%'
        }
        pos="absolute"
        left={0}
        top={0}
        bgColor={color.success}
        transition={`${autoplayDuration ?? TRANSITION_TIME * 1000}ms linear`}
      >
        <Flex
          height={spacing.toRem(20)}
          transform={showTag ? '' : 'scaleY(0)'}
          transition={`${TRANSITION_TIME}s ease`}
          width={spacing.toRem(20)}
          pos="absolute"
          right={0}
          top={showTag ? `-${spacing.toRem(18)}` : 0}
          borderRadius={`${spacing.M.rem} ${spacing.M.rem} 0 0`}
          bgColor={color.success}
          justifyContent="center"
          // We want it to be white for both themes
          color={color.light.textInverted}
          style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
          pt={spacing.toRem(1)}
          zIndex={1000}
          overflow="hidden"
        >
          {currentValue}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProgressBar;
