import { spacing, Img, Box } from 'deepstash-ui';
import React from 'react';
import { Reaction, UserReaction } from 'types/models';
import UserFollowRow from '../user/UserFollowRow';

interface ReactionUserEntryProps {
  userReaction: UserReaction;
  allReactions: Reaction[];
  showSeparator?: boolean;
}

const ReactionUserEntry: React.FC<ReactionUserEntryProps> = ({
  userReaction,
  allReactions,
  showSeparator,
}) => {
  return (
    <Box
      _hover={undefined}
      width={{ base: spacing.toRem(300), lg: spacing.toRem(340) }}
      display="flex"
      flexDir="row"
      pos="relative"
    >
      <UserFollowRow
        user={userReaction.user}
        showSeparator={showSeparator}
        photoCornerComponent={
          <Img
            width="20px"
            height="20px"
            position="absolute"
            bottom="0rem"
            right="0rem"
            src={allReactions[userReaction.reaction.id - 1]?.url}
          />
        }
        p={spacing.XS.rem}
      />
    </Box>
  );
};

export default ReactionUserEntry;
