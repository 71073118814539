import { HeadingSize } from './../types/globalTypes';
import { Source } from './../types/models';
import useRouter from 'hooks/useRouter';
import { useMemo } from 'react';

/**
 * hook used to decide which type of heading should be used as a style based on some criterias:
 *
 * if is on the sourcePage - h1
 *
 * if is on the stash | topic \ search - h2
 *
 * if is on the feed - h5
 *
 * else - h3
 * @param source
 */
export const useHeadingType = (source: Source) => {
  const router = useRouter();

  const path = router.asPath.split('/');

  const isMainArticleOnIdeaPage = useMemo(
    () =>
      path[1] === 'idea' &&
      source.ideas?.some(x => x.id.toString() === path[2]),
    [path, source],
  );
  const isMainArticleOnSourcePage = useMemo(
    () => path[1] === 'article' && path[2] === source.id.toString(),
    [path, source],
  );

  const headingType: 'p' | HeadingSize = useMemo(() => {
    if (isMainArticleOnSourcePage) return 'h1';
    if (isMainArticleOnIdeaPage) return 'p';
    if (
      path[1] === 'stash' ||
      path[1] === 'topic' ||
      path[1] === 'search' ||
      path[1] === 'book'
    )
      return 'h2';
    if (path[1] === '') return 'h5';
    return 'h4'; // profile and similar articles go here
  }, [isMainArticleOnIdeaPage, isMainArticleOnSourcePage, source]);

  return {
    isMainArticleOnIdeaPage,
    isMainArticleOnSourcePage,
    headingType,
  };
};
