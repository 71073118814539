import {
  Box,
  color,
  Flex,
  Link,
  PrimaryText,
  spacing,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import NextLink from 'src/components/navigation/NextLink';
import { onLinkClick } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';

interface DropdownFooterInteractWithAppProps {
  description: string;
}

const DropdownFooterInteractWithApp: React.FC<DropdownFooterInteractWithAppProps> =
  ({ description }) => {
    const { colorMode } = useColorMode();

    return (
      <Flex
        align="center"
        pos="sticky"
        flexDir="column"
        bgColor={color[colorMode].top}
        p={spacing.M.rem}
        onClick={e => e.stopPropagation()}
      >
        <PrimaryText type="bold" color={color[colorMode].text}>
          {description}
        </PrimaryText>
        <Box
          width="100%"
          mt={spacing.S.rem}
          bgColor={color[colorMode].primary.default}
          color={color[colorMode].textInverted}
          textAlign="center"
          borderRadius="full"
          py={spacing.XXS.rem}
          _hover={{
            boxShadow:
              '0px 6px 12px -6px rgba(0, 0, 0, 0.15), 0px 6px 18px -4px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
          }}
        >
          <NextLink href="/mobile-app" passHref>
            <Link
              _hover={undefined}
              _active={undefined}
              _focus={undefined}
              onClick={onLinkClick}
            >
              <PrimaryText type="bold" size="m">
                {actionStrings.getDeepstashApp}
              </PrimaryText>
            </Link>
          </NextLink>
        </Box>
      </Flex>
    );
  };

export default DropdownFooterInteractWithApp;
