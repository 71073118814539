import {
  color,
  Dropdown,
  Flex,
  FlexProps,
  HeadphonesSvg,
  spacing,
  Text,
  typography,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import PublicPageStoreCard from 'src/page-components/public-pages/components/PublicPageStoreCard';
import useAuth from 'src/providers/hooks/useAuth';
import useNsi from 'src/providers/hooks/useNsi';
import { dropdownMenuStyle } from 'utils/global';

const ListenButton: React.FC<FlexProps> = props => {
  const { colorMode } = useColorMode();

  const { isLoggedIn } = useAuth();
  const { nsiDispatch } = useNsi();

  const onPressDropdownButton = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoggedIn) {
      e.stopPropagation();
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-in',
        },
      });
    }
  };

  const renderDropdownButton = () => (
    <Flex
      alignItems="center"
      position="relative"
      _hover={{ cursor: 'pointer' }}
      onClick={onPressDropdownButton}
      {...props}
    >
      <HeadphonesSvg width={spacing.toRem(24)} color={color[colorMode].text} />
    </Flex>
  );

  return (
    <Dropdown
      dropdownButtonRenderer={renderDropdownButton}
      wrapperButtonStyle={{
        _hover: undefined,
        _active: undefined,
        borderRadius: 0,
        bgColor: 'unset',
      }}
      menuStyle={dropdownMenuStyle}
      wrapperStyle={{ padding: 0, zIndex: 11 }}
      precomputeDimensions={{
        precomputeDimensionsRender: false,
        expectedHeight: 160,
        expectedWidth: 160,
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingTop={spacing.M.rem}
        color={color[colorMode].text}
      >
        <Text {...typography.primaryHeadingFonts.small.semiBold}>
          Get the app to listen to 200,000+ ideas
        </Text>
        <PublicPageStoreCard />
      </Flex>
    </Dropdown>
  );
};

export default ListenButton;
