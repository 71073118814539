import { Flex, spacing } from 'deepstash-ui';
import useAuth from 'providers/hooks/useAuth';
import useSource from 'providers/hooks/useSource';
import React from 'react';
import ShareArticleDropdown from 'src/components/share/ShareArticleDropdown';
import SourceDropdownMenu from 'src/page-components/source/components/SourceDropdownMenu';
import StashSourceButton from 'src/page-components/source/components/header/StashSourceButton';
import SourceCommentsDropdown from 'src/page-components/source/components/header/comments-dropdown/SourceCommentsDropdown';
import SourceReactionsDropdown from 'src/page-components/source/components/header/reactions-dropdown/SourceReactionDropdown';
import ListenButton from './ListenButton';

interface InteractionBarProps {
  /**
   * @default 'inline'
   */
  layout?: 'corner' | 'inline';
}

const InteractionBar: React.FC<InteractionBarProps> = ({
  layout = 'inline',
}) => {
  const { source, sourceDispatch } = useSource();
  const { isLoggedIn } = useAuth();

  const onSaveSource = () =>
    sourceDispatch({
      type: 'toggle-save-source',
      payload: { isSaved: true },
    });

  const onUnsaveSource = () =>
    sourceDispatch({
      type: 'toggle-save-source',
      payload: { isSaved: false },
    });

  if (!source) return null;

  return (
    <Flex
      alignItems="flex-end"
      gridGap={spacing.toRem(layout === 'inline' ? 14 : 8)}
    >
      <ShareArticleDropdown m={spacing.toRem(8)} source={source} />
      <ListenButton m={spacing.toRem(8)} />
      <StashSourceButton
        ideas={source.ideas}
        source={source}
        withoutText
        onSourceStashed={onSaveSource}
        onSourceUnstashed={onUnsaveSource}
        _hover={{ backgroundColor: undefined }}
        m={spacing.toRem(8)}
      />
      <SourceReactionsDropdown source={source} />
      <SourceCommentsDropdown m={spacing.toRem(8)} source={source} />
      {isLoggedIn && (
        <SourceDropdownMenu ownerId={source.userData.id} type={'article'} />
      )}
    </Flex>
  );
};

export default InteractionBar;
