import { Box, color, useColorMode, useIsMobileView } from 'deepstash-ui';
import { updateStash } from 'api/Stash';
import { BaseEmoji, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React, { useEffect, useRef } from 'react';
import useActivity from 'src/providers/hooks/useActivity';
import { Analytics, Events } from 'src/services/analytics';

interface EmojiDropdownProps {
  onEmojiSelect: (emoji: string) => void;
  handleOutsideClick?: () => void;
  id?: number;
  dropdownContainerRef?: React.MutableRefObject<HTMLButtonElement | null>;
  zIndex?: number;
}

const EmojiDropdown: React.FC<EmojiDropdownProps> = ({
  onEmojiSelect,
  id,
  handleOutsideClick,
  dropdownContainerRef,
}) => {
  const { colorMode } = useColorMode();

  const isMobileView = useIsMobileView();

  const { activity, activityDispatch } = useActivity();
  const { name } = activity?.stashes.find(stash => stash.id === id) || {};

  const dropdownRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const updateStashEmoji = async (emoji: string) => {
    if (!activity || emoji === '') return;
    if (name && id) {
      Analytics.logEvent({
        eventName: Events.organising.editStash,
        properties: {
          stashId: id,
          element: 'image',
        },
        platforms: ['amplitude'],
      });

      activityDispatch({
        type: 'mutate',
        payload: {
          activity: {
            stashes: activity.stashes.map(stash => {
              if (stash.id === id) {
                stash.emoji = emoji;
              }
              return stash;
            }),
          },
        },
      });
      await updateStash(id, { name, emoji: emoji });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownContainerRef?.current &&
        !dropdownContainerRef.current.contains(event.target)
      ) {
        handleOutsideClick && handleOutsideClick();
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <Box
      position="absolute"
      top={id ? '2.5rem' : '9rem'}
      zIndex={1}
      ref={dropdownRef}
    >
      <Picker
        emoji=":point_up_2:"
        theme={colorMode}
        title="Pick an emoji"
        skinEmoji=":hand:"
        showSkinTones={false}
        showPreview={false}
        emojiTooltip
        emojiSize={24}
        onSelect={(emoji: BaseEmoji) => {
          onEmojiSelect(emoji.native);
          id && updateStashEmoji(emoji.native);
        }}
        perLine={isMobileView || id ? 6 : 9}
        style={{
          fontFamily: 'Source Sans Pro',
          color: color[colorMode].textSecondary,
        }}
        i18n={{ search: 'Search emoji...' }}
      />
    </Box>
  );
};

export default EmojiDropdown;
