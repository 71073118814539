import { StyleProps } from 'deepstash-ui';
import React from 'react';
import { arePropsEqual } from 'utils/global';
import SourceCommentsDropdownContainer, {
  SourceCommentsDropdownContainerProps,
} from './SourceCommentsDropdownContainer';

const MemoSourceDropdownContainer = React.memo<
  SourceCommentsDropdownContainerProps & StyleProps
>(
  props => <SourceCommentsDropdownContainer {...props} />,
  (prevProps, nextProps) =>
    arePropsEqual<SourceCommentsDropdownContainerProps>(
      prevProps,
      nextProps,
      prevProps?.sourceId ?? 0,
      nextProps?.sourceId ?? 0,
    ),
);

MemoSourceDropdownContainer.displayName = 'MemoSourceDropdownContainer';
export default MemoSourceDropdownContainer;
