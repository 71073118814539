import { SourceContext } from '../source/SourceProvider';
import {
  SourceProviderAction,
  SourceProviderContextType,
  SourceProviderState,
} from '../source/SourceProviderTypes';
import useCreateContextConsumer from './useCreateContextConsumer';

const useSource = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    SourceProviderState,
    SourceProviderAction,
    SourceProviderContextType
  >({ context: SourceContext, withLogging: true });

  return {
    ...contextConsumer,
    sourceDispatch: dispatch,
  };
};

export default useSource;
