import { UpdateElementResponse } from 'api/api.types';
import { updateProfileInfo, updateUserPreferences } from 'api/Profile';
import { UserProfile } from 'types';
import { getApiToken } from 'api/apiRequest';

export const updateUserPrefs = async (
  userId: number,
  userData: Partial<UserProfile>,
): Promise<string> => {
  const token = getApiToken();

  return updateUserPreferences(token, userId, userData).then(
    () => {
      return 'Success';
    },
    error => {
      return 'Error when trying to update the settings: ' + error.toString();
    },
  );
};

export const updateUserProf = async (
  userData: Partial<UserProfile>,
): Promise<UpdateElementResponse | null | string> => {
  const token = getApiToken();

  return updateProfileInfo(token, userData);
};

export const isLoggedInNoOnboarding = ({
  profile,
  isLoggedIn,
}: {
  profile?: UserProfile;
  isLoggedIn: boolean;
}) => {
  return (
    isLoggedIn &&
    !!profile &&
    !profile.showOnboardingTooltips?.achieveQuestionAnswers &&
    (profile.department === null || profile.department === undefined) &&
    !profile.didOnboarding
  );
};
