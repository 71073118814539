import {
  color,
  Flex,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useMemo } from 'react';
import { UserData } from 'types/models';
import NextImage from '../image/NextImage';
import NextLink from '../navigation/NextLink';

interface UserToStashProps {
  user: UserData;
  /**
   * A string that appears near the username.
   */
  extraText?: string;
  /**
   * Wether to have a static sourceCard that is not interactable
   */
  isNotInteractable?: boolean;
  preload?: boolean;
}

const SourceAuthor: React.FC<UserToStashProps & StyleProps> = ({
  user,
  extraText,
  isNotInteractable,
  preload,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const profilePictureAltTag = useMemo(
    () =>
      `${user.firstName} ${user.lastName} (@${user.username}) - Profile Photo`,
    [user.firstName, user.lastName, user.username],
  );

  // const [showProfileInfo, setShowProfileInfo] = useState(false);
  // const onHoverStart = () => {
  //   setShowProfileInfo(true);
  // };
  // const onHoverEnd = () => {
  //   setShowProfileInfo(false);
  // };

  return (
    <Flex
      mb={spacing.XXS.rem}
      alignItems="center"
      onClick={e => e.stopPropagation()}
      // onMouseEnter={onHoverStart}
      // onMouseLeave={onHoverEnd}
      color={color[colorMode].textSecondary}
      flexWrap={'wrap'}
      zIndex={2}
      position="relative"
      {...props}
    >
      <NextLink href={`/u/${user.username}`} passHref>
        <Link
          display="flex"
          alignItems="center"
          _hover={{
            textDecoration: 'underline',
          }}
          _active={undefined}
          _focus={undefined}
          {...(isNotInteractable ? { target: '_blank' } : {})}
        >
          <NextImage
            wrapperStyle={{
              width: spacing.toRem(24),
              height: spacing.toRem(24),
              borderRadius: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
            imageUrl={
              user.photo ?? 'https://static.deepstash.com/profile/1.png'
            }
            alt={profilePictureAltTag}
            /* The blurhas component doesn't want an empty string or null. So we collapse thouse into undefiend */
            blurhash={user.photo_blurhash}
            priority={preload}
          />

          <PrimaryText
            ml={spacing.XS.rem}
            size="s"
            color={props.color ?? color[colorMode].primary}
          >
            {user.firstName} {user.lastName}
          </PrimaryText>
        </Link>
      </NextLink>
      {extraText && (
        <PrimaryText ml={'1cw'} size="s" color={color[colorMode].textSecondary}>
          {extraText}
        </PrimaryText>
      )}
      {/* {showProfileInfo && (
        <Box
          position="absolute"
          top={24}
          left={0}
          zIndex={2}
          borderRadius="1rem"
          boxShadow="0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)"
        >
          <ProfileCard
            user={user}
            withBackground
            size={'lg'}
            backgroundColor={color[colorMode].surface}
            w={{ base: '100%', md: getRemWidthFromColumns(4) }}
            p={GAP_SIZE_REM}
          />
        </Box>
      )} */}
    </Flex>
  );
};

export default SourceAuthor;
