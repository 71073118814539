import { spacing } from 'deepstash-ui';
import ProgressBar from 'src/page-components/collection/old/ProgressBar';
import React from 'react';
import useCollection from 'providers/hooks/useCollection';
import { Source } from 'types/models';

interface SourceImageProgressProps {
  source: Source;
}

const SourceImageProgress: React.FC<SourceImageProgressProps> = ({
  source,
}) => {
  const { collection } = useCollection();
  const readIdeasCount =
    collection?.progress?.sourcesProgress[source.id]?.readIdeas.length ?? 0;

  if (!collection) return null;

  return (
    <ProgressBar
      width="100%"
      height="2.5%"
      pos="absolute"
      left={0}
      bottom={0}
      borderRadius={spacing.XS.rem}
      currentValue={readIdeasCount}
      totalValue={source.ideas.length}
      direction="horizontal"
    />
  );
};

export default SourceImageProgress;
