import { TopicApiResponse } from 'api/api.types';
import { Topic } from 'types';

export function normalizeTopic(topic: TopicApiResponse): Topic {
  return {
    name: topic.name,
    categoryOrder: topic.category_order,
    image: topic.image_url,
    id: topic.id,
  };
}

export function normalizeTopics(topics: TopicApiResponse[]): Topic[] {
  return topics.map(topic => normalizeTopic(topic));
  // .filter(it => it.categoryOrder > 0);
}
