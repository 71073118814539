import {
  AppStoreButton,
  color,
  Flex,
  PrimaryText,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import LandingStoreCard from 'src/page-components/landing/components/LandingStoreCard';

interface PublicPageStoreCardProps {
  title?: React.ReactChild;
  justButton?: boolean;
}

const PublicPageStoreCard: React.FC<PublicPageStoreCardProps & StyleProps> = ({
  title,
  justButton,
  ...props
}) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      {...props}
    >
      <PrimaryText
        size="m"
        color={color[colorMode].textDisabled}
        type="bold"
        textAlign="center"
      >
        {title}
      </PrimaryText>
      {justButton ? (
        <AppStoreButton store="Apple" />
      ) : (
        <LandingStoreCard isAboutPageVariant {...props} />
      )}
    </Flex>
  );
};

export default PublicPageStoreCard;
