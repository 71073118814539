export const toastStrings = {
  noSourceTitle: {
    title: 'Almost ready to publish',
    description: 'Before you publish, pick a title for your source',
  },
  noSourcePublish: {
    title: 'Almost ready to publish',
    description:
      'Before you publish, let others know where these ideas came from: an article, a book or yourself',
  },
  noIdeasToPublish: {
    title: 'Add some ideas',
    description: 'Nothing to publish just yet. Add some ideas to your source',
  },
  noPickedType: {
    title: 'Almost ready to publish',
    description:
      'Before you publish, let others know where these ideas came from: a link, a book or yourself.',
  },
  noAuthor: {
    title: 'Almost ready to publish',
    description:
      "Don't forget to add authors to quote, they are much harder to understand without.",
  },
  emptyIdea: {
    title: 'Almost ready to publish',
    description:
      'You have an empty idea. Before publishing finish the idea or remove it.',
  },
  charsOverLimit: {
    title: 'Almost ready to publish',
    description: "Keep ideas under 600 characters, they're easier to read.",
  },
  publishCelebration: {
    finish: 'Well done! Now share it with your friends too!',
  },
  linkCopied: 'Link copied to your clipboard ',
  contentCopied: 'Content copied to your clipboard ',
  editorIdeaDropdown: {
    deleteSuccess: 'The idea has been successfully deleted from your library.',
    deleteConfirmation:
      'Are you sure you want to delete this idea? It cannot be undone.',
  },
  ideaCardDropdown: {
    deleteIdeaTitle: 'Idea deleted!',
    deleteIdeaDescritpion: `The idea has been successfully deleted from your library.`,
  },
  imagePickerTabUpload: {
    title: 'Image too big',
    description: 'Images must be smaller than 1MB',
  },
  importSource: {
    title: `You haven't selected a source for your ideas yet so they are not
    saved. Are you sure you want to switch to another draft?`,
  },
  conflictingSource: {
    draft: {
      link: {
        title: 'The link already exists in your drafts',
        description:
          "There's a similar draft with the same link in your account",
        primaryButton: 'Try another link',
        secondaryButton: 'Edit existing draft',
      },
      book: {
        title: 'The book already exists in your drafts',
        description:
          "There's a similar draft with the same book in your account",
        primaryButton: 'Try another book',
        secondaryButton: 'Edit existing draft',
      },
    },
    published: {
      link: {
        title: 'You published this link already',
        description: "There's a similar link published in your account",
        primaryButton: 'Try another link',
        secondaryButton: 'Edit existing link',
      },
      book: {
        title: 'You published this book already',
        description: "There's a similar book published in your account",
        primaryButton: 'Try another book',
        secondaryButton: 'Edit existing book',
      },
    },
  },
  completeCollection: {
    finishedCollection: 'You finished reading this collection.',
    greetings: [
      'Nice work',
      'Great progress',
      'Great job',
      'Congrats',
      'Cool beans',
    ],
  },
};
export default toastStrings;
