import {
  CheckFillSvg,
  color,
  Flex,
  spacing,
  StyleProps,
  Text,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import { numberFormatter } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';
import { getSingularOrPlural } from 'utils/StringUtils';

interface IdeaCounterProps {
  ideaCount: number;
  readCount?: number;
  totalReadCount?: number;
  totalSaveCount?: number;
  isDraft?: boolean;
  underlineColor?: string;
  textColor?: string;
}

const IdeaCounter: React.FC<IdeaCounterProps & StyleProps> = ({
  ideaCount,
  isDraft,
  readCount,
  totalReadCount,
  totalSaveCount,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const ideaNoun = isDraft
    ? commonStrings.draft.toLowerCase()
    : commonStrings.idea.toLowerCase();

  return (
    <Flex
      gridGap={spacing.XS.rem}
      alignItems="center"
      flexShrink={0}
      key={ideaCount}
      color={color[colorMode].textSecondary}
      {...props}
    >
      {readCount === ideaCount && <CheckFillSvg color={color.success} />}
      <Text>
        {ideaCount} {getSingularOrPlural(ideaCount, ideaNoun, ideaNoun + 's')}
      </Text>
      {readCount !== undefined && (
        <>
          {'·'}
          <Text>{readCount} read</Text>
        </>
      )}
      {(totalReadCount || totalSaveCount) && '·'}
      {totalReadCount !== undefined && (
        <Text>
          {totalReadCount === 0 ? 'No' : numberFormatter(totalReadCount)}{' '}
          {getSingularOrPlural(totalReadCount, 'read', 'reads')}
        </Text>
      )}
      {totalSaveCount !== undefined && (
        <Text>
          {totalSaveCount === 0 ? 'No' : numberFormatter(totalSaveCount)}{' '}
          {'saves'}
        </Text>
      )}
    </Flex>
  );
};

export default IdeaCounter;
