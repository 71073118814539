import {
  color,
  Flex,
  Heading,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import SourceImageV2 from 'src/page-components/source/components/header/source-image/SourceImageV2';
import { getRemWidthFromColumns, slugify } from 'utils/global';
import { getSourceSubtitle } from 'utils/source.utils';
import { SourceCollapsedProps } from './SourceCollapsed';
import { SourceType } from 'types/enums';

const SourceCollapsedSmall: React.FC<SourceCollapsedProps & StyleProps> = ({
  source,
  preload,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const sourceUrl = useMemo(
    () => (source ? `/article/${source.id}/${slugify(source.title)}` : ''),
    [source?.id],
  );

  const router = useRouter();

  if (!source) return null;

  return (
    <Flex
      width={getRemWidthFromColumns(2)}
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      flexShrink={0}
      cursor="pointer"
      pt={spacing.M.rem}
      onClick={() => router.push(sourceUrl)}
      {...props}
    >
      <SourceImageV2
        size={spacing.toRem(160)}
        source={source}
        preload={preload}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="start"
        mt={spacing.M.rem}
        height="64px"
        whiteSpace={'break-spaces'}
      >
        <Link href={sourceUrl} _hover={undefined}>
          <Heading
            size="h4"
            fontFamily="Source Sans Pro"
            fontSize="1rem"
            style={{ lineHeight: '1.25rem' }}
            fontWeight={700}
            lineHeight={spacing.toRem(22)}
            noOfLines={2}
            textAlign="center"
            textDecoration="none"
            pb={spacing.XXS.rem}
            color={color[colorMode].text}
          >
            {source.title}
          </Heading>
        </Link>
        {source.sourceType !== SourceType.YOURSELF && (
          <PrimaryText
            textAlign="center"
            size="s"
            noOfLines={1}
            color={color[colorMode].textSecondary}
          >
            {getSourceSubtitle(source)}
          </PrimaryText>
        )}
      </Flex>
    </Flex>
  );
};

export default SourceCollapsedSmall;
