import { getApiUrl } from './global';
import { client, getApiToken } from 'api/apiRequest';

const updateOptions = (url: string, _token?: string) => {
  const token = _token ?? getApiToken({ allowDefault: true });

  return {
    baseURL: getApiUrl(),
    headers: {
      Authorization: `Token ${
        // If that query param is present, user the default token
        url.includes('type=user')
          ? getApiToken({ allowDefault: true, forceDefault: true })
          : token
      }`,
    },
  };
};

export default async function apiQueryClient<QueryData>(
  url: string,
  token?: string,
): Promise<QueryData> {
  if (url === '') {
    //We do this to prevent an API call to "/api/v2" when url is an empty string
    //This violates typing.
    //useInfiniteScroll must also check if the url it's trying to fetch is an empty string to return an empty array instead of an array with an empty object
    return {} as any;
  }
  const correctUrl =
    url === '/current/'
      ? `/current/?updated&tz=${new Date().getTimezoneOffset()}`
      : url;
  const { data } = await client.get(
    correctUrl,
    updateOptions(correctUrl, token),
  );
  return data;
}
