import {
  Button,
  color,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  TextSize,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import NextLink from 'src/components/navigation/NextLink';
import { CHIP_MARGIN_BOTTOM, CHIP_PADDING_Y } from 'utils/constants';
import BaseCard from '../cards/BaseCard';

interface ChipProps {
  /**
   * pass the string or the React Element that you want to show in the chip
   */
  content: string | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isFollowDisabled?: boolean;
  href?: string;
  counter?: number;
  textSize?: TextSize;
  id?: string;
  hasHoverEffect?: boolean;
}

const Chip = React.forwardRef<HTMLDivElement | null, ChipProps & StyleProps>(
  (
    {
      content,
      onClick,
      isFollowDisabled,
      href,
      counter,
      textSize,
      id,
      hasHoverEffect = true,
      ...props
    },
    ref,
  ) => {
    const { colorMode } = useColorMode();
    const isMobileView = useIsMobileView();
    const renderCounter = () =>
      counter !== undefined ? (
        <PrimaryText
          color={color[colorMode].textDisabled}
          borderRadius="0.8rem"
          bg={color[colorMode].surface}
          marginLeft="0.3rem"
          px={spacing.toRem(12)}
          py={spacing.toRem(4)}
          marginRight="-0.5rem"
          id={id ? `${id}-counter` : undefined}
        >
          {counter}
        </PrimaryText>
      ) : null;

    return href ? (
      <NextLink
        href={href}
        passHref
        color={props.textColor ?? color[colorMode].textSecondary}
      >
        <Link _focus={undefined} _hover={undefined}>
          <BaseCard
            ref={ref}
            align="center"
            py={spacing.toRem(CHIP_PADDING_Y)}
            mb={spacing.toRem(CHIP_MARGIN_BOTTOM)}
            px={spacing.M.rem}
            mr={spacing.XS.rem}
            cursor="pointer"
            id={id ? `${id}-container` : undefined}
            hasHoverEffect={hasHoverEffect}
            cardSize="sm"
            {...props}
          >
            <PrimaryText
              id={id}
              size={textSize}
              color={props.color ?? color[colorMode].textSecondary}
            >
              {content}
            </PrimaryText>
            {renderCounter()}
          </BaseCard>
        </Link>
      </NextLink>
    ) : (
      <Button
        cursor="pointer"
        py={spacing.XXS.rem}
        transition="all 0.3s cubic-bezier(.11,.57,.44,.8)"
        _hover={
          hasHoverEffect
            ? {
                ...props._hover,
                transform: `translateY(-4px)`,
                boxShadow: `0px 4px 0px 0px ${color[colorMode].underground}CC`,
              }
            : { ...props._hover }
        }
        _active={{ bgColor: color[colorMode].background }}
        _focus={undefined}
        outline="none"
        boxShadow={'none'}
        _disabled={{ cursor: 'pointer' }}
        px={{ base: spacing.S.rem, lg: spacing.S.rem }}
        borderRadius={spacing.toRem(100)}
        onClick={onClick}
        isDisabled={isFollowDisabled}
        mr={{ base: spacing.XS.rem, lg: spacing.M.rem }}
        mb={{ base: spacing.XS.rem, lg: spacing.M.rem }}
        {...props}
      >
        <PrimaryText
          size={textSize ?? isMobileView ? 's' : 'm'}
          color={props.color ?? color[colorMode].textSecondary}
        >
          {content}
        </PrimaryText>
        {renderCounter()}
      </Button>
    );
  },
);

Chip.displayName = 'Chip';
export default Chip;
