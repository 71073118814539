import React, { useEffect, useRef } from 'react';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';
import {
  StyleProps,
  Flex,
  Box,
  spacing,
  color,
  useColorMode,
} from 'deepstash-ui';
import styles from './styles/Tabs.module.scss';

export type TabData = {
  /**
   * Whether or not the tab is disabled
   */
  disabled?: boolean;
  /**
   * The component to be rendered for this tab
   */
  scene: JSX.Element;
  /**
   * @param isSelected whether the tab is selected
   * A function that renders the tab in its selected state
   */
  tabRenderer: (isSelected: boolean) => JSX.Element;
};

interface TabsProps {
  tabs: TabData[];
  onTabSelected: (index: number) => void;
  selectedTab: number;
  tabsClassName?: string;
  /**
   * whether to show divider or not
   */
  showDivider?: boolean;
}

const Tabs: React.FC<TabsProps & StyleProps> = ({
  tabs,
  onTabSelected,
  selectedTab,
  tabsClassName,
  showDivider = false,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const renderedTabs = useRef<number[]>([]);
  useEffect(() => {
    renderedTabs.current = [...new Set([...renderedTabs.current, selectedTab])];
  }, [selectedTab]);

  return (
    <ReactTabs onSelect={onTabSelected} selectedIndex={selectedTab}>
      <Flex flexDir="column" w="100%" alignItems="center" {...props}>
        <TabList className={`${styles.tabs} ${tabsClassName}`}>
          <Flex w="100%">
            {tabs.map(
              (tab, index) =>
                !tab.disabled && (
                  <Tab key={index} className={styles.tab}>
                    {tab.tabRenderer(selectedTab === index)}
                  </Tab>
                ),
            )}
          </Flex>
        </TabList>
        {showDivider && (
          // this divider has to be bigger to cover the padding
          <Box
            h={spacing.toRem(4)}
            w={`calc(100% + 2*${spacing.toRem(32)})`}
            bgColor={color[colorMode].background}
          />
        )}
      </Flex>
      {tabs.map(
        (tab, index) =>
          !tab.disabled && (
            <TabPanel
              key={index}
              className={styles.tab_panel}
              forceRender={renderedTabs.current.includes(index)}
            >
              <Flex
                display={selectedTab !== index ? 'none' : 'flex'}
                flexDir="column"
              >
                {tab.scene}
              </Flex>
            </TabPanel>
          ),
      )}
    </ReactTabs>
  );
};

export default React.memo(Tabs);
