import { useMemo } from 'react';
import { diffTimeSinceTimestamp } from 'utils/date-utils';

/**
 * hook to extract the passed time since the timestamp was created
 */
export const useDiffSinceTimestamp = (createdAt: string) => {
  const timestamp = useMemo(
    () => diffTimeSinceTimestamp(createdAt),
    [createdAt],
  );

  return { timestamp };
};
