import { StyleProps } from 'deepstash-ui';
import { useHeadingType } from 'hooks/useHeadingType';
import dynamic from 'next/dynamic';
import React from 'react';
import { SourceType } from 'types/enums';
import { Source } from 'types/models';

const SourceBookTitle = dynamic(
  () => import('./source-book-title/SourceBookTitle'),
);
const SourceArticleTitle = dynamic(
  () => import('./source-article-title/SourceArticleTitle'),
);
const SourceIndependentTitle = dynamic(
  () => import('./source-independent-title/SourceIndependentTitle'),
);
const SourcePodcastTitle = dynamic(
  () => import('./source-podcast-title/SourcePodcastTitle'),
);
const SourceVideoTitle = dynamic(
  () => import('./source-image/SourceVideoTitle'),
);

export type SourceTitleByTypeSize = 'sm' | 'md' | 'lg';

interface SourceTitleByTypeProps {
  source: Source;
  size?: SourceTitleByTypeSize;
  /**
   * Whether or not there is a link over the resource info
   */
  withLink?: boolean;
  /**
   * @default true
   */
  showPlayer?: boolean;
  /**
   * Whether to have a static sourceTitle that is not interactable
   */
  isNotInteractable?: boolean;
  /**
   * Whether we should preload the image or not
   */
  preload?: boolean;
  /**
   * How many lines of text should be shown before truncating it
   * @default 2
   */
  numberOfLines?: number;
  /**
   * If we want a custom title
   */
  titleOverride?: string;
}

const NO_OF_LINES = 2;

const SourceTitleByType: React.FC<SourceTitleByTypeProps & StyleProps> = ({
  source,
  size,
  withLink,
  showPlayer,
  isNotInteractable,
  preload,
  numberOfLines = NO_OF_LINES,
  titleOverride,
  ...props
}) => {
  const { headingType } = useHeadingType(source);

  switch (source.sourceType) {
    case SourceType.ARTICLE:
      return (
        <SourceArticleTitle
          source={source}
          titleHeadingSize={headingType}
          noOfLines={numberOfLines}
          withLink={withLink}
          size={size}
          zIndex={1}
          isNotInteractable={isNotInteractable}
          preload={preload}
          {...props}
        />
      );
    case SourceType.BOOK:
      return (
        <SourceBookTitle
          noOfLines={numberOfLines}
          source={source}
          titleHeadingSize={headingType}
          withLink={withLink}
          size={size}
          zIndex={1}
          isNotInteractable={isNotInteractable}
          preload={preload}
          titleOverride={titleOverride}
          {...props}
        />
      );
    case SourceType.VIDEO:
      return (
        <SourceVideoTitle
          withLink={withLink}
          noOfLines={numberOfLines}
          source={source}
          titleHeadingSize={headingType}
          showPlayer={showPlayer}
          size={size}
          zIndex={1}
          isNotInteractable={isNotInteractable}
          preload={preload}
          {...props}
        />
      );
    case SourceType.PODCAST:
      return (
        <SourcePodcastTitle
          source={source}
          noOfLines={numberOfLines}
          size={size}
          showPlayer={showPlayer}
          titleHeadingSize={headingType}
          withLink={withLink}
          zIndex={1}
          isNotInteractable={isNotInteractable}
          preload={preload}
          {...props}
        />
      );
    case SourceType.YOURSELF:
      return (
        <SourceIndependentTitle
          noOfLines={numberOfLines}
          source={source}
          titleHeadingSize={headingType}
          withLink={withLink}
          zIndex={1}
          size={size}
          isNotInteractable={isNotInteractable}
          preload={preload}
          {...props}
        />
      );
    default:
      return source.status === 2 ? (
        <SourceIndependentTitle
          noOfLines={numberOfLines}
          source={source}
          titleHeadingSize={headingType}
          withLink={withLink}
          zIndex={1}
          size={size}
          isNotInteractable={isNotInteractable}
          preload={preload}
          {...props}
        />
      ) : null;
  }
};
export default React.memo(SourceTitleByType);
