import {
  Box,
  Button,
  color,
  DownArrowSvg,
  Flex,
  Input,
  Modal,
  PrimaryHeading,
  Text,
  useColorMode,
  useIsMobileView,
  spacing,
} from 'deepstash-ui';
import { BaseEmoji, emojiIndex } from 'emoji-mart';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { UserStash } from 'types';
import EmojiDropdown from '../../dropdown/EmojiDropdown';
import StashModalFooter from '../../../page-components/stash/components/stash-modal/StashModalFooter';
import useStashModalInternals from '../../../page-components/stash/components/stash-modal/useStashModalInternals';

const STASH_TITLE_LIMIT = 30;

interface StashModal {
  isOpen: boolean;
  onClose: () => void;
  isUpdate?: boolean;
  stash?: UserStash;
  afterNewStashPress?: (id: number) => void;
}

const StashModal: React.FC<StashModal> = ({
  isOpen,
  onClose,
  isUpdate,
  stash,
  afterNewStashPress,
}) => {
  const [renderDropdown, setRenderDropdown] = useState(false);
  const [stashName, setStashName] = useState('');

  const isMobileView = useIsMobileView();

  //Pick and calculate a random emoji every time the modal opens
  const randomEmoji: BaseEmoji = useMemo(() => {
    const keys = Object.keys(emojiIndex.emojis);
    return emojiIndex.emojis[
      keys[Math.floor(Math.random() * keys.length)]
    ] as BaseEmoji;
  }, [isOpen]);

  //Emoji state
  const [emoji, setEmoji] = useState<BaseEmoji['native']>(
    isUpdate ? (stash ? stash.emoji : '') : randomEmoji.native ?? '😎',
  );

  const { addNewStash, onDeleteStash, onUpdateStash } = useStashModalInternals(
    isUpdate ? stash : undefined,
    emoji,
    stashName,
    setStashName,
    setEmoji,
    onClose,
    afterNewStashPress,
  );

  useEffect(() => {
    if (!stash) {
      setEmoji('');
      setStashName('');
      return;
    }
    setEmoji(stash.emoji);
    setStashName(stash.name);
  }, [stash, isUpdate]);

  //Set the emoji after the random emoji has changed
  useEffect(() => {
    !isUpdate && setEmoji(randomEmoji.native ?? '😎');
  }, [randomEmoji]);

  const { colorMode } = useColorMode();

  const dropdownContainerRef: React.MutableRefObject<HTMLButtonElement | null> =
    useRef(null);

  const checkForEnter = async (e: any) => {
    e.which = e.which || e.keyCode;
    if (e.which == 13) {
      addNewStash();
    }
  };

  const renderFooter = () => (
    <StashModalFooter
      addNewStash={addNewStash}
      onClose={onClose}
      isUpdate={isUpdate}
      stash={stash}
      emoji={emoji}
      onDeleteStash={e => {
        onDeleteStash(e), onClose();
      }}
      onUpdateStash={onUpdateStash}
      stashName={stashName}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      py={spacing.L.rem}
      renderFooter={renderFooter}
      headerTitle={
        <Box color={color[colorMode].text}>
          <PrimaryHeading size="h2" textSize="m" type="bold">
            {isUpdate ? 'UPDATE YOUR STASH' : 'CREATE A STASH'}
          </PrimaryHeading>
        </Box>
      }
      w={isMobileView ? 'calc(100% - 1rem)' : spacing.toRem(440)}
    >
      <Box px="0" py={spacing.XL.rem}>
        <Flex>
          <Button
            _focus={undefined}
            _hover={undefined}
            _active={undefined}
            borderRadius={spacing.toRem(100)}
            backgroundColor={color[colorMode].top}
            color={color[colorMode].textDisabled}
            border="unset"
            px={spacing.M.rem}
            py={spacing.XS.rem}
            minW="unset"
            mr={spacing.XS.rem}
            onClick={() => setRenderDropdown(!renderDropdown)}
            ref={dropdownContainerRef}
          >
            <Text fontSize="1.3125rem">{emoji}</Text>

            <Box pl={spacing.XS.rem}>
              <DownArrowSvg color={color[colorMode].textSecondary} />
            </Box>
          </Button>
          {renderDropdown && (
            <EmojiDropdown
              onEmojiSelect={emoji => {
                setEmoji(emoji);
                setRenderDropdown(false);
              }}
              handleOutsideClick={() => setRenderDropdown(false)}
              dropdownContainerRef={dropdownContainerRef}
            />
          )}
          <Input
            _focus={undefined}
            _hover={undefined}
            _active={undefined}
            borderRadius="full"
            placeholder="Give it a name..."
            color={color[colorMode].textSecondary}
            _placeholder={{ color: color[colorMode].textDisabled }}
            backgroundColor={color[colorMode].top}
            border="unset"
            px={spacing.M.rem}
            fontWeight="bold"
            value={stashName}
            onChange={(e: React.FormEvent<any>) => {
              e.currentTarget.value.length <= STASH_TITLE_LIMIT &&
                setStashName(e.currentTarget.value);
            }}
            onKeyUp={checkForEnter}
            w="100%"
          />
        </Flex>
      </Box>
    </Modal>
  );
};

export default StashModal;
