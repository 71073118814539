import { StashApiResponse } from 'api/api.types';
import { Stash } from 'types/models';
import { normalizeUserData } from './profile';
import { normalizeIdea, normalizeSource } from './source';
import { Normalizer } from './types';

export const normalizeStash: Normalizer<StashApiResponse, Stash> = stash => {
  return {
    emoji: stash.emoji,
    id: stash.id,
    ideaCount: stash.insights_count ?? 0,
    name: stash.name,
    user: stash.user_data ? normalizeUserData(stash.user_data) : undefined,
    description: stash.description,
    ideas: (stash.blocks ?? []).map(idea => normalizeIdea(idea)),
    sort: stash.sort,
    sources: (stash.articles ?? []).map(source => normalizeSource(source)),
    imageUrl: stash.image,
    imagePreview: stash.image_preview ?? undefined,
  };
};

export function isStash(val: any): val is Stash {
  return (val as Stash).ideaCount !== undefined;
}
