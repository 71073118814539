import { getShortenedLink } from 'api/Article';
import {
  Box,
  color,
  Dropdown,
  DropdownProps,
  Flex,
  SharedSvg,
  spacing,
  StyleProps,
  useColorMode,
  useIsDesktopView,
  useToast,
} from 'deepstash-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Source } from 'types';
import { TOAST_TIME } from 'utils/constants';
import { toastStrings } from 'utils/strings';
import styles from 'src/theme/Scrollbar.module.scss';
import Skeleton from 'react-loading-skeleton';
import {
  InlineShareButtons,
  InlineShareButtonsConfig,
} from 'sharethis-reactjs';
import { BLOCK_WIDTH, BLOCK_WIDTH_REM, dropdownMenuStyle } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';

interface ShareArticleDropdownProps {
  source: Source;
  dropdownProps?: DropdownProps;
}

const ShareArticleDropdown: React.FC<
  ShareArticleDropdownProps & StyleProps
> = ({ source, dropdownProps, ...props }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [link, setLink] = useState(commonStrings.loading);
  const isDesktopView = useIsDesktopView();

  useEffect(() => {
    getShortenedLink({
      source,
      socialImage: source.image ?? '',
      socialTitle: source.title,
    }).then(res => setLink(res.shortLink));
  }, [source]);

  const onPressDropdownButton = (_e: React.MouseEvent<HTMLDivElement>) => {
    toast({
      title: toastStrings.linkCopied,
      status: 'success',
      duration: TOAST_TIME,
      isClosable: true,
    });
    navigator.clipboard.writeText(link);
  };

  const shareConfigDesktop: InlineShareButtonsConfig | undefined = useMemo(
    () =>
      link !== commonStrings.loading
        ? {
            alignment: 'center',
            color: 'social',
            enabled: true,
            labels: null,
            language: 'en',
            networks: [
              'facebook',
              'twitter',
              'pinterest',
              'linkedin',
              'reddit',
              'getpocket',
              'email',
              'sharethis',
            ] as any[],
            radius: 26,
            size: 48,
            padding: 12,
            font_size: 16,
            show_total: false,
            url: link,
            title: source.title ?? '',
          }
        : undefined,
    [link],
  );

  const shareConfigMobileTop: InlineShareButtonsConfig | undefined = useMemo(
    () =>
      link !== commonStrings.loading
        ? {
            alignment: 'center',
            color: 'social',
            enabled: true,
            labels: null,
            language: 'en',
            networks: ['facebook', 'twitter', 'pinterest', 'linkedin'] as any[],
            radius: 26,
            size: 48,
            padding: 12,
            font_size: 16,
            show_total: false,
            url: link,
            title: source.title ?? '',
          }
        : undefined,
    [link],
  );

  const shareConfigMobileBottom: InlineShareButtonsConfig | undefined = useMemo(
    () =>
      link !== commonStrings.loading
        ? {
            alignment: 'center',
            color: 'social',
            enabled: true,
            labels: null,
            language: 'en',
            networks: ['reddit', 'getpocket', 'email', 'sharethis'] as any[],
            radius: 26,
            size: 48,
            padding: 12,
            font_size: 16,
            show_total: false,
            url: link,
            title: source.title ?? '',
          }
        : undefined,
    [link],
  );

  const renderDropdownButton = () => (
    <Flex
      alignItems="center"
      _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={onPressDropdownButton}
      {...props}
    >
      <SharedSvg color={color[colorMode].text} />
    </Flex>
  );

  return (
    <Dropdown
      dropdownButtonRenderer={renderDropdownButton}
      wrapperButtonStyle={{
        _hover: undefined,
        _active: undefined,
        borderRadius: 0,
        bgColor: 'unset',
      }}
      menuStyle={dropdownMenuStyle}
      // We want the share dropdown to be over the navbar
      wrapperStyle={{
        padding: 0,
        marginLeft: isDesktopView ? 240 : 0,
      }}
      precomputeDimensions={{
        precomputeDimensionsRender: false,
        expectedHeight: isDesktopView ? BLOCK_WIDTH : BLOCK_WIDTH * 2,
        expectedWidth: isDesktopView ? 480 : 240,
      }}
      {...dropdownProps}
    >
      {() => (
        <Box
          backgroundColor={color[colorMode].surface}
          borderRadius={spacing.M.rem}
          height={
            isDesktopView ? BLOCK_WIDTH_REM : spacing.toRem(BLOCK_WIDTH * 2)
          }
          mr={spacing.XS.rem}
          width="100%"
          p={spacing.XS.rem}
          overflowX="hidden"
          overflowY="auto"
          border="none"
          onClick={e => e.stopPropagation()}
          zIndex={210}
          className={
            colorMode === 'light'
              ? styles.scrollVerticalLightSurface
              : styles.scrollVerticalDarkSurface
          }
        >
          {link === commonStrings.loading && (
            <Skeleton height={BLOCK_WIDTH_REM} width="100%" />
          )}
          {shareConfigDesktop && isDesktopView && (
            <Box width="100%">
              <InlineShareButtons config={shareConfigDesktop} />
            </Box>
          )}
          {shareConfigMobileTop && !isDesktopView && (
            <Box width="100%" mb={spacing.M.rem}>
              <InlineShareButtons config={shareConfigMobileTop} />
            </Box>
          )}
          {shareConfigMobileBottom && !isDesktopView && (
            <Box width="100%">
              <InlineShareButtons config={shareConfigMobileBottom} />
            </Box>
          )}
        </Box>
      )}
    </Dropdown>
  );
};

export default ShareArticleDropdown;
