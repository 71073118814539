import {
  ProgressAvatar,
  ProgressAvatarSize,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useMemo } from 'react';
import NextImage from 'src/components/image/NextImage';

interface OwnProps {
  size: ProgressAvatarSize;
  photo: string;

  showReads?: boolean;
  totalSegments?: number;
  filledSegments?: number;
  altTag?: string;
  preload?: boolean;
  /**
   * When true, the image modal is showed on clicking the image
   */
  enableShowingImageModal?: boolean;
  /**
   * Blur Loading for image
   */
  imageBlurhash?: string;
  blurDataUrl?: string;
  imageWrapperStyle?: StyleProps;
}

const ProfilePicture: React.FC<OwnProps & StyleProps> = ({
  size,
  photo,
  totalSegments,
  filledSegments,
  showReads,
  altTag,
  preload,
  enableShowingImageModal,
  imageBlurhash,
  blurDataUrl,
  imageWrapperStyle,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const ProfileImage = useMemo(() => {
    return (
      <NextImage
        wrapperStyle={{
          width: '100%',
          height: '100%',
          borderRadius: '10rem',
          position: 'relative',
          overflow: 'hidden',
          cursor: enableShowingImageModal ? 'pointer' : '',
          ...imageWrapperStyle,
        }}
        imageUrl={photo}
        alt={altTag ?? ''}
        /* The blurhas component doesn't want an empty string or null. So we collapse thouse into undefiend */
        blurhash={imageBlurhash ? imageBlurhash : undefined}
        blurDataUrl={blurDataUrl ? blurDataUrl : undefined}
        priority={preload}
        imageModal={enableShowingImageModal ? 'rounded' : undefined}
        imageModalSize={{ width: '300px', height: '300px' }}
      />
    );
  }, [photo]);

  return (
    <ProgressAvatar
      colorMode={colorMode}
      currentRead={filledSegments ?? 0}
      totalRead={totalSegments ?? 5}
      showReads={showReads ?? false}
      boxSizing="border-box"
      size={size}
      image={ProfileImage}
      textAlign="left"
      {...props}
    />
  );
};

export default ProfilePicture;
