import { CollectionProviderReducer } from './CollectionProviderTypes';

const collectionReducer: CollectionProviderReducer = (state, action) => {
  switch (action.type) {
    case 'complete-collection': {
      const newState = { ...state };
      if (newState.collection) {
        newState.collection.progress.completed = true;
      }
      return newState;
    }
    case 'complete-source': {
      const { sourceId } = action.payload;

      const newState = { ...state };
      const targetSourceProgress =
        newState.collection?.progress.sourcesProgress[sourceId];
      if (targetSourceProgress) {
        targetSourceProgress.completed = true;
      }
      return newState;
    }
    case 'update-reads': {
      const { reads, sourceId } = action.payload;

      const newState = { ...state };
      if (!newState.collection) {
        return state;
      }

      if (!newState.collection.progress.sourcesProgress[sourceId]) {
        newState.collection.progress.sourcesProgress[sourceId] = {
          readIdeas: [],
          completed: false,
        };
      }
      const targetSourceProgress =
        newState.collection.progress.sourcesProgress[sourceId];

      reads?.forEach(ideaId => {
        if (
          newState.collection &&
          !targetSourceProgress.readIdeas.includes(ideaId)
        ) {
          targetSourceProgress.readIdeas.push(ideaId);
          newState.collection.progress.totalReadIdeas++;
          if (
            targetSourceProgress.readIdeas.length ===
            newState.collection.sources.find(
              source => source.id === sourceId ?? 0,
            )?.ideas.length
          ) {
            targetSourceProgress.completed = true;
          }
        }
      });

      return newState;
    }
    case 'set-source': {
      const { source } = action.payload;
      return {
        ...state,
        source: source,
      };
    }
    case 'set-collection': {
      const { collection, source } = action.payload;
      return {
        collection: collection,
        source: source,
      };
    }
    default: {
      return state;
    }
  }
};

export default collectionReducer;
