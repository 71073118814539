import { Flex, spacing } from 'deepstash-ui';
import React from 'react';
import { Reaction, UserReaction } from 'types/models';
import { TRANSITION_TIME } from 'utils/constants';
import ReactionUserEntry from './ReactionUserEntry';

interface ReactionsUserListProps {
  reactions: UserReaction[];
  allReactions: Reaction[];
}

const ReactionsUserList: React.FC<ReactionsUserListProps> = ({
  reactions,
  allReactions,
}) => {
  return (
    <Flex
      flexDir="column"
      width="100%"
      alignItems="center"
      transition={`${TRANSITION_TIME}s`}
      mt={spacing.toRem(40)}
    >
      {reactions.map((userReaction, index) => (
        <ReactionUserEntry
          key={`reaction-item-${userReaction.reaction.id}-${index}`}
          userReaction={userReaction}
          allReactions={allReactions}
          showSeparator={index !== 0}
        />
      ))}
    </Flex>
  );
};

export default ReactionsUserList;
