import { Source, SourceType } from 'types';
import { getDomainName } from 'utils/global';

export const getSourceSubtitle = (source: Source) =>
  source.sourceType === SourceType.ARTICLE && source.url
    ? getDomainName(source.url)
    : source.sourceType === SourceType.YOURSELF
    ? source.description
    : source.author;

/**
 * @Return the sourceType singular from its plural
 * Used on explore page
 * Doesn't work with individual or unknown
 * @Returns Empty string by default
 * @param sourceType
 */
export const getSourceTypeFromPlural = (sourceType: string) => {
  switch (sourceType) {
    case 'articles': {
      return 'article';
    }
    case 'books': {
      return 'book';
    }
    case 'podcasts': {
      return 'podcast';
    }
    case 'videos': {
      return 'video';
    }
    default: {
      return '';
    }
  }
};

/**
 * @Return the sourceType plural from its singular
 * Used on explore page
 * Doesn't work with individual or unknown
 * @Returns Empty string by default
 * @param sourceType
 */
export const getSourceTypePlural = (sourceType: string) => {
  switch (sourceType) {
    case 'article': {
      return 'articles';
    }
    case 'book': {
      return 'books';
    }
    case 'podcast': {
      return 'podcasts';
    }
    case 'video': {
      return 'videos';
    }
    default: {
      return '';
    }
  }
};

export const getSourceThemeColor = (
  sourceType: SourceType,
  colorMode: 'light' | 'dark',
) => {
  switch (sourceType) {
    case SourceType.ARTICLE: {
      return colorMode === 'dark' ? '#01487F' : '#407AA7';
    }
    case SourceType.BOOK: {
      return colorMode === 'dark' ? '#D6C7B0' : '#E4DACB';
    }
    case SourceType.PODCAST: {
      return colorMode === 'dark' ? '#0F78AA' : '#4B9CC2';
    }
    case SourceType.VIDEO: {
      return colorMode === 'dark' ? '#CE3542' : '#DD6E77';
    }
    default: {
      return colorMode === 'dark' ? '#6B2970' : '#945B98';
    }
  }
};
