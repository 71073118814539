import { Box, Flex, FlexProps, StyleProps } from 'deepstash-ui';
import useAuth from 'providers/hooks/useAuth';
import React from 'react';
import NextImage from 'src/components/image/NextImage';
import { Source } from 'types';
import { getRemWidthFromColumns } from 'utils/global';
import SourceImageProgress from './SourceImageProgress';

export interface SourceImageProps extends StyleProps {
  source: Source;
  /**
   * Whether to have a static image that is not interactable
   */
  isNotInteractable?: boolean;
  /**
   * Should preload
   * @default false
   */
  preload?: boolean;
  hasShadow?: boolean;
  size?: string | number;
}

const SourceImageV3: React.FC<SourceImageProps & FlexProps> = ({
  source,
  preload,
  size,
  ...props
}) => {
  const { isLoggedIn } = useAuth();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      pos="relative"
      flexShrink={0}
      zIndex={1}
      w={size ?? getRemWidthFromColumns(3)}
      h={size ?? getRemWidthFromColumns(3)}
      {...props}
    >
      <Box
        position="absolute"
        width="60%"
        height="60%"
        backgroundColor={source?.imageColor}
        top={'50%'}
        left={'50%'}
        transform={'translateX(-50%) translateY(-50%)'}
        filter={`blur(calc(${size ?? getRemWidthFromColumns(3)} / 10))`}
      />
      <NextImage
        imageUrl={source.image}
        alt={source.title}
        blurhash={source.imageBlurhash}
        defaultImage={'/default_article_image.png'}
        wrapperStyle={{
          width: '100%',
          height: '100%',
        }}
        isNotInteractable={props?.isNotInteractable}
        objectFit="contain"
        priority={preload}
      />
      {isLoggedIn && <SourceImageProgress source={source} />}
    </Flex>
  );
};

export default SourceImageV3;
