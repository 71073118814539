import {
  Box,
  Button,
  color,
  Dropdown,
  DropdownItem,
  Flex,
  HyperlinkButton,
  PrimaryHeading,
  PrimaryText,
  spacing,
  StashedFullSvg,
  LockedStashedSvg,
  StashedSvg,
  StyleProps,
  useColorMode,
  useDisclosure,
  useIsMobileView,
  LockSvg,
} from 'deepstash-ui';
import { useCheckPaywall } from 'hooks/paywall';
import useLocation from 'hooks/useLocation';
import useNsiActivity from 'providers/hooks/useNsiActivity';
import usePaywall from 'providers/hooks/usePaywall';
import useProfile from 'providers/hooks/useProfile';
import React, {
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import StashOptionsIdeasCount from 'src/components/stash-options-menu/StashOptionsIdeasCount';
import useActivity from 'src/providers/hooks/useActivity';
import useAuth from 'src/providers/hooks/useAuth';
import useNsi from 'src/providers/hooks/useNsi';
import { PaywallAnalyticsLocation, Source, UserStash } from 'types';
import { TRANSITION_TIME } from 'utils/constants';
import { trimStashName } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import StashModal from '../modals/stash-modal/StashModal';
import styles from './StashOptionsMenu.module.scss';

const MOVE_TO_STASH_TIMEOUT = 3000;

interface OwnProps {
  /**
   * The id of the current used stash
   */
  selectedStashId?: number;
  /**
   * Whether is already stashed
   */
  isStashed: boolean;
  /**
   * Call for stashing a source
   * @param stashId the id of the stash
   */
  onStash: (stashId: number) => void;
  /**
   * Call for unstashing a source
   * @param id optional prop, used for SourceUnstashing
   */
  onUnstash: () => void;
  /**
   * Function to get the label for this stash options menu
   */
  getLabel?: () => JSX.Element;
  ideaId?: number;
  source: Source;
  id?: string;
  dropdownPositionX?: 'left' | 'right';
}

const StashOptionsMenu: React.FC<OwnProps & StyleProps> = ({
  selectedStashId,
  isStashed,
  onStash,
  onUnstash,
  getLabel,
  id,
  ideaId,
  source,
  dropdownPositionX = 'right',
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  const { activity } = useActivity();
  const { profile } = useProfile();
  const isOwnSource = profile?.id === source.userData.id;
  const { nsiActivityDispatch, getCanUseNsiActivity } = useNsiActivity();
  const { nsiDispatch } = useNsi();
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const { isUserAllowedToSaveIdeas } = useCheckPaywall();
  const [moveToStash, setMoveToStash] = useState(false);

  const {
    isOpen: isNewStashModalOpen,
    onOpen: onNewStashModalOpen,
    onClose: onNewStashModalClose,
  } = useDisclosure();

  const { remainingStashes, currentStashed } = useMemo(() => {
    const stashes = activity?.stashes ?? [];

    return {
      remainingStashes: stashes?.filter(
        stash => stash.id !== selectedStashId,
      ) as UserStash[],
      currentStashed: stashes?.find(
        stash => stash.id === selectedStashId,
      ) as UserStash,
    };
  }, [activity?.stashes, selectedStashId]);

  const onSaveClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    stashId: number,
    onDone?: () => void,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (isUserAllowedToSaveIdeas()) {
      onStash(stashId);
    } else {
      onPaywallModalOpen({
        location: PaywallAnalyticsLocation.MoveToStashDropdown,
      });
    }
    onDone?.();
  };

  const stashMenuItem = (
    stash: UserStash,
    onClose: () => void,
    index?: number,
  ) => {
    const { id: stashId, name, emoji, ideaCount } = stash;
    return (
      <DropdownItem
        onClick={e => {
          onSaveClick(e, stashId, onClose);
        }}
        borderRadius={spacing.S.rem}
        backgroundColor={color[colorMode].top}
        color={color[colorMode].text}
        _focus={undefined}
        _hover={{
          boxShadow:
            '0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)',
        }}
        _active={{ backgroundColor: color[colorMode].surface }}
        key={`StashMenuOption${index}`}
        my={spacing.S.rem}
        p={spacing.M.rem}
        display="flex"
        id={id ? `${id}-stash-${stashId}-button` : undefined}
      >
        <PrimaryText
          size="m"
          mr={spacing.XXS.rem}
          id={id ? `${id}-stash-${stashId}-emoji` : undefined}
        >
          {emoji}
        </PrimaryText>
        <Flex
          justifyContent="space-between"
          width="100%"
          id={id ? `${id}-stash-${stashId}-stash-name-wrapper` : undefined}
        >
          <PrimaryText
            textOverflow="ellipsis"
            size="m"
            type="bold"
            id={id ? `${id}-stash-${stashId}-stash-name` : undefined}
          >
            {trimStashName(name, isMobileView)}
          </PrimaryText>
          <StashOptionsIdeasCount ideasCount={ideaCount} />
        </Flex>
      </DropdownItem>
    );
  };

  const headerStashMenu = (onClose: () => void) => (
    <>
      {currentStashed && (
        <PrimaryHeading
          size="h2"
          mx={spacing.XS.rem}
          type="bold"
          color={color[colorMode].text}
        >
          {actionStrings.savedIn}
        </PrimaryHeading>
      )}
      <DropdownItem
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          !isOwnSource && onUnstash();
          onClose();
        }}
        borderRadius={spacing.S.rem}
        backgroundColor={color[colorMode].background}
        color={color[colorMode].text}
        _focus={undefined}
        _active={{ backgroundColor: color[colorMode].surface }}
        my={spacing.S.rem}
        p={spacing.M.rem}
        display="flex"
      >
        {currentStashed ? (
          <PrimaryText size="m" mr={spacing.XXS.rem}>
            {currentStashed.emoji}
          </PrimaryText>
        ) : (
          <StashedFullSvg ml="-4px" mr="4px" />
        )}
        <Flex justifyContent="space-between" width="100%">
          <PrimaryText textOverflow="ellipsis" size="m" type="bold">
            {currentStashed
              ? trimStashName(currentStashed.name, false)
              : actionStrings.saved}
          </PrimaryText>
          {!isOwnSource && (
            <Box
              color={color[colorMode].primary.default}
              borderRadius={spacing.M.rem}
              px={spacing.XXS.rem}
              py={spacing.XXS.rem}
            >
              <PrimaryText type="bold" fontSize="12px">
                {actionStrings.unsave}
              </PrimaryText>
            </Box>
          )}
        </Flex>
      </DropdownItem>
    </>
  );

  const { onPaywallModalOpen } = usePaywall();

  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const onStartMoveTimeout = () => {
    if (isUserAllowedToSaveIdeas()) {
      setMoveToStash(true);
      timeoutRef.current = setTimeout(() => {
        setMoveToStash(false);
      }, MOVE_TO_STASH_TIMEOUT);
    }
  };

  const onDropdownButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (!isStashed && isLoggedIn && !isUserAllowedToSaveIdeas()) {
      e.stopPropagation();
      onPaywallModalOpen({ location: PaywallAnalyticsLocation.SaveIdeaButton });
    }
    if (!isLoggedIn || !activity) {
      e.stopPropagation();
      if (getCanUseNsiActivity()) {
        if (ideaId) {
          nsiActivityDispatch({
            type: 'set-idea-stashed',
            payload: {
              ideaId,
              source,
              willStash: !isStashed,
            },
          });
        } else {
          // We are stashing / unstashing a source
          if (isStashed) {
            nsiActivityDispatch({
              type: 'unstash-source',
              payload: {
                source,
                ideasIds: source.ideas.map(idea => idea.id),
              },
            });
          } else {
            nsiActivityDispatch({
              type: 'stash-source',
              payload: {
                source,
                ideasIds: source.ideas.map(idea => idea.id),
              },
            });
          }
        }
      } else {
        nsiDispatch({
          type: 'openAuth',
          payload: {
            type: 'sign-in',
          },
        });
      }
      return;
    }
    if (!isStashed) {
      onSaveClick(e, -1, onStartMoveTimeout);
    }
  };

  const renderDropdownButton = (isOpen?: boolean) => (
    <Box pos="relative">
      <Button
        borderRadius={spacing.toRem(100)}
        pr={0}
        pl={0}
        color={color[colorMode].text}
        _focus={undefined}
        _active={undefined}
        onClick={onDropdownButtonClick}
        className={
          isStashed && !isOpen
            ? styles.stashed_expand_animation
            : styles.stashed_collapse_animation
        }
        id={id ? `${id}-stash-dropdown-button` : undefined}
      >
        <Flex
          alignItems="center"
          _hover={
            getLabel
              ? { textDecor: 'underline' }
              : {
                  backgroundColor: color[colorMode].top,
                  cursor: 'pointer',
                  borderRadius: 'full',
                  ...props._hover,
                }
          }
          px={spacing.XS.rem}
          py={spacing.XS.rem}
          id={id ? `${id}-stash-dropdown-label-wrapper` : undefined}
        >
          {moveToStash ? (
            <PrimaryText
              size="m"
              type="bold"
              color={color[colorMode].text}
              whiteSpace="nowrap"
            >
              {actionStrings.move.toUpperCase()}
            </PrimaryText>
          ) : isStashed ? (
            <StashedFullSvg id={id ? `${id}-stash-dropdown-icon` : undefined} />
          ) : isLoggedIn && !isUserAllowedToSaveIdeas() ? (
            <LockedStashedSvg
              fill={color[colorMode].text}
              id={id ? `${id}-stash-dropdown-icon` : undefined}
            />
          ) : (
            <StashedSvg id={id ? `${id}-stash-dropdown-icon` : undefined} />
          )}
          {getLabel?.()}
        </Flex>
      </Button>
      {currentStashed && (
        <Flex
          pos="absolute"
          top={spacing.toRem(-20)}
          left={spacing.toRem(-20)}
          bg={colorMode === 'dark' ? color.light.surface : color.dark.surface}
          opacity={0}
          className={isLoggedIn ? styles.stash_animation : undefined}
          borderRadius={spacing.toRem(100)}
          align="center"
          py={spacing.XXS.rem}
          px={spacing.XS.rem}
        >
          <PrimaryText
            type="bold"
            color={
              colorMode === 'light'
                ? color.dark.textDisabled
                : color.light.textDisabled
            }
            whiteSpace="nowrap"
          >
            {actionStrings.savedIn.toUpperCase()}:
          </PrimaryText>
          <PrimaryText
            size="m"
            type="bold"
            ml={spacing.XXS.rem}
            color={color[colorMode].textInverted}
            whiteSpace="nowrap"
          >
            {currentStashed?.emoji}{' '}
            {trimStashName(currentStashed?.name, isMobileView)}
          </PrimaryText>
        </Flex>
      )}
    </Box>
  );

  return (
    // We add a zIndex to have it placed above source images for 'Yourself' sources (and thus clickable).
    // 'Yourself' SVGs are much larger than their apparent size and they overlap with the button,
    // thus rendering them unclickable without this zIndex.
    <Box zIndex={1}>
      <Dropdown
        dropdownButtonRenderer={renderDropdownButton}
        wrapperButtonStyle={{
          _hover: undefined,
          _active: undefined,
          borderRadius: 0,
          bgColor: 'unset',
        }}
        menuStyle={{
          boxShadow:
            '0px 10px 32px -4px rgba(0,0,0, 0.1), 0px 6px 14px -6px rgba(0,0,0, 0.12)',
        }}
        dropdownPositionX={dropdownPositionX}
        //In the publish modal we always want this dropdown to open upwards since the modal fills the screen and there is little space on the bottom
        dropdownPositionY={location === 'studio' ? 'top' : undefined}
      >
        {onClose => (
          <Box>
            <Box
              backgroundColor={color[colorMode].surface}
              borderRadius={spacing.M.rem}
              mr={isMobileView ? spacing.toRem(60) : spacing.toRem(80)}
              maxH={spacing.toRem(320)}
              width="100%"
              overflowX="hidden"
              overflowY="scroll"
              p={spacing.S.rem}
              border="none"
              zIndex={210}
            >
              {isStashed && headerStashMenu(onClose)}

              <Flex align="flex-start">
                {remainingStashes.length !== 0 && (
                  <>
                    {!isUserAllowedToSaveIdeas() && (
                      <LockSvg color={color[colorMode].pro.primary} />
                    )}
                    <PrimaryHeading
                      size="h2"
                      mx={spacing.XS.rem}
                      type="bold"
                      color={color[colorMode].text}
                    >
                      {isStashed
                        ? actionStrings.moveToStash
                        : actionStrings.saveIn}
                    </PrimaryHeading>
                  </>
                )}
              </Flex>
              <Box transition={`${TRANSITION_TIME}s`}>
                {remainingStashes.map((stash, index) =>
                  stashMenuItem(stash, onClose, index),
                )}
              </Box>
              <DropdownItem
                borderRadius={spacing.S.rem}
                _hover={undefined}
                _focus={undefined}
                _active={undefined}
                my={spacing.XXS.rem}
              >
                <HyperlinkButton
                  colorMode={colorMode}
                  onClick={onNewStashModalOpen}
                >
                  <PrimaryText size="m" type="semiBold">
                    {actionStrings.newStash}
                  </PrimaryText>
                </HyperlinkButton>
              </DropdownItem>
            </Box>
          </Box>
        )}
      </Dropdown>
      {/* use the modal outside of Dropdown to avoid auto-dismiss when selecting Input */}
      {isNewStashModalOpen && (
        <StashModal
          isOpen={isNewStashModalOpen}
          onClose={onNewStashModalClose}
          afterNewStashPress={
            isUserAllowedToSaveIdeas()
              ? onStash
              : () => {
                  onPaywallModalOpen({
                    location: PaywallAnalyticsLocation.MoveToStashDropdown,
                  });
                }
          }
        />
      )}
    </Box>
  );
};

export default StashOptionsMenu;
