import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  StyleProps,
  color,
  useColorMode,
} from 'deepstash-ui';
import useAuth from 'providers/hooks/useAuth';
import React from 'react';
import NextImage from 'src/components/image/NextImage';
import { Source, SourceType } from 'types';
import { getRemWidthFromColumns } from 'utils/global';
import SourceImageProgress from './SourceImageProgress';

export const POST_IMAGE_STYLE: Record<SourceType, BoxProps> = {
  // Article
  0: {
    width: '70%',
    height: '70%',
    borderRadius: '5%',
    zIndex: 2,
  },
  // Book
  1: {
    w: '50%',
    h: '75%',
    zIndex: 2,
    borderTopRightRadius: '5%',
    borderBottomRightRadius: '5%',
  },
  // Video
  2: {
    width: '80%',
    height: '45%',
    zIndex: 2,
    borderRadius: '5%',
  },
  // Podcast
  3: {
    w: '66%',
    h: '66%',
    zIndex: 2,
    borderRadius: '100%',
  },
  // Yourself
  4: {
    width: '66%',
    height: '66%',
    borderRadius: '1rem',
    zIndex: 2,
  },
  // Unknown
  5: {},
};

export interface SourceImageProps extends StyleProps {
  source: Source;
  /**
   * Whether to have a static image that is not interactable
   */
  isNotInteractable?: boolean;
  /**
   * Should preload
   * @default false
   */
  preload?: boolean;
  hasShadow?: boolean;
  size?: string | number;
}

const SourceImageV2: React.FC<SourceImageProps & FlexProps> = ({
  source,
  preload,
  size,
  hasShadow = true,
  ...props
}) => {
  const { isLoggedIn } = useAuth();
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      pos="relative"
      flexShrink={0}
      zIndex={1}
      borderRadius="10%"
      overflow="hidden"
      w={size ?? getRemWidthFromColumns(3)}
      h={size ?? getRemWidthFromColumns(3)}
      bgColor={`${color[colorMode].surface}88`}
      boxShadow={
        hasShadow
          ? `0px calc(${size} / 40) calc(${size} / 10) rgba(0, 0, 0, 0.1); 0px calc(${size} / 160) calc(${size} / 40) rgba(0, 0, 0, 0.2);`
          : 0
      }
      {...props}
    >
      <Box
        position="absolute"
        width="55%"
        height="55%"
        backgroundColor={source?.imageColor}
        top={'50%'}
        left={'50%'}
        transform={'translateX(-50%) translateY(-50%)'}
        filter={`blur(calc(${
          size
            ? typeof size === 'number'
              ? `${size}px`
              : size
            : getRemWidthFromColumns(3)
        } / 10))`}
      />
      <NextImage
        imageUrl={source.image}
        alt={source.title}
        blurhash={source.imageBlurhash}
        defaultImage={'/assets/default_article_image.png'}
        wrapperStyle={POST_IMAGE_STYLE[source.sourceType]}
        isNotInteractable={props?.isNotInteractable}
        objectFit="cover"
        priority={preload}
      />
      {isLoggedIn && <SourceImageProgress source={source} />}
    </Flex>
  );
};

export default SourceImageV2;
