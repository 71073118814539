import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { Source } from 'src/types/models';
import {
  SourceProviderContextType,
  SourceProviderReducer,
  SourceProviderState,
} from './SourceProviderTypes';
import sourceReducer from './SourceReducer';
import { RelatedPostsResponse } from 'api/api.types';

const INITIAL_STATE: SourceProviderState = {
  source: undefined,
  similarSources: undefined,
  relatedPosts: undefined,
};

export const SourceContext = createContext<SourceProviderContextType>({
  dispatch: () => {
    return;
  },
  state: INITIAL_STATE,
});

SourceContext.displayName = 'SourceContext';

interface SourceProviderProps {
  /**
   * @param {Source} source - The normalized souce
   * to be stored in the context
   * @default undefined
   */
  source?: Source;
  /**
   * @param {Source[]} similarSources - The normalized sources
   * similar to the initial one
   * @default undefined
   */
  similarSources?: Source[];
  relatedPosts?: RelatedPostsResponse;
}

const SourceProvider: React.FC<SourceProviderProps> = ({
  source,
  similarSources,
  relatedPosts,
  children,
}) => {
  const [state, dispatch] = useReducer<SourceProviderReducer>(sourceReducer, {
    source,
    similarSources,
    relatedPosts,
  });

  useEffect(() => {
    dispatch({
      type: 'set-source',
      payload: {
        source,
      },
    });
  }, [source?.id]);

  useEffect(() => {
    dispatch({
      type: 'set-similar-sources',
      payload: {
        similarSources,
      },
    });
  }, [similarSources]);

  useEffect(() => {
    dispatch({
      type: 'set-related-posts',
      payload: {
        relatedPosts,
      },
    });
  }, [relatedPosts]);

  const contextValue = useMemo<SourceProviderContextType>(
    () => ({
      dispatch,
      state,
    }),
    [dispatch, state],
  );

  return (
    <SourceContext.Provider value={contextValue}>
      {children}
    </SourceContext.Provider>
  );
};

export default SourceProvider;
