import {
  color,
  Flex,
  PrimaryButton,
  PrimaryText,
  useColorMode,
  spacing,
} from 'deepstash-ui';
import React, { useEffect, useState } from 'react';
import { UserStash } from 'types';

interface StashModalFooterProps {
  onClose: () => void;
  isUpdate?: boolean;
  stash?: UserStash;
  addNewStash: () => void;
  onDeleteStash: (e: React.MouseEvent) => void;
  onUpdateStash: () => void;
  stashName: string;
  emoji: string;
}

const StashModalFooter: React.FC<StashModalFooterProps> = ({
  onClose,
  isUpdate,
  stash,
  addNewStash,
  onDeleteStash,
  onUpdateStash,
  stashName,
  emoji,
}) => {
  const { colorMode } = useColorMode();

  const initialStashName = stash?.name;
  const initialEmoji = stash?.emoji;

  const [isDisabled, setIsDisbaled] = useState(true);

  useEffect(() => {
    if (isUpdate) {
      if (!initialStashName || !initialEmoji) return;

      if (stashName !== initialStashName || emoji !== initialEmoji) {
        setIsDisbaled(false);
      } else {
        setIsDisbaled(true);
      }
    } else {
      if (stashName.length > 0) {
        setIsDisbaled(false);
      } else {
        setIsDisbaled(true);
      }
    }
  }, [stashName, emoji, initialStashName, initialEmoji, isUpdate]);

  return (
    <Flex
      mb={spacing.XS.rem}
      justifyContent="flex-start"
      p="0px"
      w="100%"
      alignItems="center"
    >
      <PrimaryButton
        size="lg"
        disabled={isDisabled}
        cursor={stashName !== '' ? 'pointer' : 'default'}
        onClick={() => (isUpdate ? onUpdateStash() : addNewStash())}
        mr={spacing.M.rem}
      >
        <PrimaryText size="m" type="bold" opacity={stashName !== '' ? 1 : 0.6}>
          DONE
        </PrimaryText>
      </PrimaryButton>
      <PrimaryText
        size="m"
        type="semiBold"
        color={color[colorMode].textSecondary}
        _focus={undefined}
        _hover={{ color: color[colorMode].text, cursor: 'pointer ' }}
        _active={undefined}
        ml="1rem"
        onClick={onClose}
      >
        CANCEL
      </PrimaryText>

      {isUpdate && (
        <PrimaryText
          size="m"
          color={color.failure.default}
          _focus={undefined}
          _hover={{ cursor: 'pointer' }}
          _active={undefined}
          ml="auto"
          onClick={e => onDeleteStash(e)}
        >
          DELETE
        </PrimaryText>
      )}
    </Flex>
  );
};

export default StashModalFooter;
