import {
  Flex,
  Link,
  PrimaryButton,
  PrimaryHeading,
  PrimaryText,
  StyleProps,
  Text,
  color,
  spacing,
  typography,
  useColorMode,
  useIsDesktopView,
  useIsMobileView,
} from 'deepstash-ui';
import useLocation from 'hooks/useLocation';
import { useRouter } from 'next/router';
import useAuth from 'providers/hooks/useAuth';
import useNsi from 'providers/hooks/useNsi';
import useSource from 'providers/hooks/useSource';
import React, { useCallback, useMemo } from 'react';
import IdeaCounter from 'src/components/counter/IdeaCounter';
import HashtagChipCarousel from 'src/components/hashtag/HashtagChipCarousel';
import SourceImageV2 from 'src/page-components/source/components/header/source-image/SourceImageV2';
import { SourceType } from 'types/enums';
import {
  BLOCK_WIDTH_REM,
  GAP_SIZE_REM,
  getRemWidthFromColumns,
  slugify,
} from 'utils/global';
import { getSourceSubtitle, getSourceThemeColor } from 'utils/source.utils';
import actionStrings from 'utils/strings/actionStrings';
import InteractionBar from './InteractionBar';
import { SourceCollapsedProps } from './SourceCollapsed';
import SourceImageV3 from 'src/page-components/source/components/header/source-image/SourceImageV3';

const SourceCollapsedFull: React.FC<SourceCollapsedProps & StyleProps> = ({
  preload,
  source: sourceFromProps,
  shouldHideCta,
  readCount,
  headingSize,
  overrideAuthorComponent,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const { source: sourceFromProvider } = useSource();

  const source = useMemo(
    () => sourceFromProps ?? sourceFromProvider,
    [sourceFromProps, sourceFromProvider],
  );

  const sourceUrl = useMemo(
    () => (source ? `/article/${source.id}/${slugify(source.title)}` : ''),
    [source?.id],
  );

  const seoOptimizedPostTitle = useMemo(() => {
    if (source?.sourceType === SourceType.BOOK) {
      return `${
        source?.userData.firstName + ' ' + source?.userData.lastName
      }'s Key Ideas from ${source?.title}`;
    } else return source?.title ?? '';
  }, [source?.id]);

  const router = useRouter();

  const isMobileView = useIsMobileView();
  const isDesktopView = useIsDesktopView();
  const { nsiDispatch } = useNsi();

  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const onClickCtaHandler = useCallback(() => {
    if (!isLoggedIn) {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-up',
        },
      });
    }
  }, [isLoggedIn]);

  const onImageClickHandler = useCallback(() => {
    if (source) {
      router.push(sourceUrl);
    }
  }, [sourceUrl]);

  if (!source) return null;

  return (
    <Flex
      w={{
        base: '100%',
        md: getRemWidthFromColumns(8),
        lg: getRemWidthFromColumns(12),
      }}
      flexShrink={0}
      alignItems={{ base: 'center', md: 'flex-start' }}
      zIndex={1}
      gridGap={{ base: GAP_SIZE_REM, md: BLOCK_WIDTH_REM }}
      flexDirection={{ base: 'column', md: 'row' }}
      {...props}
    >
      {isMobileView ? (
        <SourceImageV3
          size={'calc(100vw - 96px)'}
          source={source}
          preload={preload}
          onClick={onImageClickHandler}
          cursor="pointer"
        />
      ) : (
        <SourceImageV2
          source={source}
          preload={preload}
          onClick={onImageClickHandler}
          cursor="pointer"
          size={getRemWidthFromColumns(3)}
        />
      )}
      <Flex
        flexDirection="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        justifyContent="start"
        color={color[colorMode].text}
        gridGap={spacing.M.rem}
        mt={spacing.M.rem}
      >
        <Link href={sourceUrl} _hover={undefined}>
          <PrimaryHeading
            textAlign={{ base: 'center', md: 'left' }}
            textDecoration="none"
            type="bold"
            {...(isMobileView
              ? typography.primaryHeadingFonts.medium.bold
              : typography.primaryHeadingFonts.large.bold)}
            lineHeight="1.125em"
            size={
              headingSize ??
              (router.pathname.startsWith('/collection')
                ? 'h2'
                : location === 'idea'
                ? 'h4'
                : 'h1')
            }
            width={{
              base: 'auto',
              md:
                seoOptimizedPostTitle.length > 80
                  ? 'auto'
                  : getRemWidthFromColumns(4),
              lg:
                seoOptimizedPostTitle.length > 80
                  ? 'auto'
                  : getRemWidthFromColumns(6),
            }}
          >
            {seoOptimizedPostTitle}
            {source.sourceType === SourceType.BOOK && (
              <span
                style={{
                  fontSize: '1.25rem',
                  fontWeight: 500,
                  color: color[colorMode].textSecondary,
                }}
              >
                <br />
                by{' '}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {source?.author}
                </span>
              </span>
            )}
          </PrimaryHeading>
        </Link>
        {source.sourceType !== SourceType.YOURSELF &&
          source.sourceType !== SourceType.BOOK && (
            <PrimaryText
              textAlign={{ base: 'center', md: 'left' }}
              {...typography.primaryHeadingFonts.medium.regular}
              noOfLines={1}
              opacity={0.6}
            >
              Curated from:{' '}
              {overrideAuthorComponent ?? getSourceSubtitle(source)}
            </PrimaryText>
          )}
        <Flex
          flexDirection="column"
          gridGap={spacing.toRem(8)}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          {(isMobileView || isDesktopView) && source.ideas[0]?.hashtags && (
            <>
              <PrimaryText
                color={color[colorMode].text}
                opacity={0.4}
                {...typography.primaryTextFonts.medium.regular}
                textAlign={{ base: 'center', md: 'left' }}
              >
                Ideas, facts & insights covering these topics:
              </PrimaryText>
              <HashtagChipCarousel
                overflow="visible"
                hashtags={source.ideas[0]?.hashtags.map(hashtag => ({
                  name: hashtag,
                }))}
                justifyContent={{ base: 'center', md: 'flex-start' }}
                gridGap={spacing.toRem(8)}
                marginBottom={spacing.toRem(8)}
              />
            </>
          )}
          {source.ideaCount && (
            <IdeaCounter
              textColor={color[colorMode].text}
              underlineColor={getSourceThemeColor(source.sourceType, colorMode)}
              ideaCount={source.ideaCount}
              readCount={readCount}
              fontSize="1em"
              totalReadCount={source.totalReads}
            />
          )}
        </Flex>
        <Flex
          alignItems="center"
          gridGap={GAP_SIZE_REM}
          my={spacing.M.rem}
          height={spacing.toRem(54)}
        >
          {!isLoggedIn && !shouldHideCta && (
            <PrimaryButton
              size="lg"
              onClick={onClickCtaHandler}
              _hover={{
                backgroundColor:
                  color[colorMode === 'dark' ? 'light' : 'dark'].underground,
              }}
              width={{ base: '100%', md: getRemWidthFromColumns(3) }}
              height="100%"
              transition="background-color 0.3s ease-out"
              backgroundColor={color[colorMode].text}
              color={color[colorMode].textInverted}
              {...typography.primaryTextFonts.large.semiBold}
              align="center"
            >
              <Text>{actionStrings.signUpToReadForFree}</Text>
            </PrimaryButton>
          )}
          {!sourceFromProps && <InteractionBar layout="inline" />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SourceCollapsedFull;
