import useActivity from 'providers/hooks/useActivity';
import { useCallback } from 'react';
import useProfile from 'src/providers/hooks/useProfile';
import {
  canUserSaveIdeas,
  canUserSeeLikedIdeas,
  canUserSeeStashedIdeas,
} from './utils';

/**
 * Used to check what actions are available for the user.
 * It returns all the necessary methods.
 * These methods use functions from src/hooks/paywall/utils.ts, so we can
 * test them.
 */
const useCheckPaywall = () => {
  const { profile } = useProfile();
  const { activity } = useActivity();
  const { subscriptionType } = profile ?? {};

  /**
   * Used to determine if we allow the user to see all saved ideas.
   */
  const isUserAllowedToSeeStashedIdeas = useCallback(() => {
    return canUserSeeStashedIdeas({ subscriptionType });
  }, [subscriptionType]);

  /**
   * Used to determine if we allow the user to see all liked ideas.
   */
  const isUserAllowedToSeeLikedIdeas = useCallback(() => {
    return canUserSeeLikedIdeas({ subscriptionType });
  }, [subscriptionType]);

  /**
   * Used to determine if we allow the user to save a new idea.
   */
  const isUserAllowedToSaveIdeas = useCallback(() => {
    return canUserSaveIdeas({
      subscriptionType,
      savedIdeaCount: activity?.stashedIdeasIds.length ?? 0,
    });
  }, [subscriptionType, activity?.stashedIdeasIds.length]);

  return {
    isUserAllowedToSeeStashedIdeas,
    isUserAllowedToSeeLikedIdeas,
    isUserAllowedToSaveIdeas,
  };
};

export default useCheckPaywall;
