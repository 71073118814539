import {
  color,
  Flex,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import IdeaCounter from 'src/components/counter/IdeaCounter';
import SourceImageV2 from 'src/page-components/source/components/header/source-image/SourceImageV2';
import { slugify } from 'utils/global';
import { getSourceSubtitle, getSourceThemeColor } from 'utils/source.utils';
import { SourceCollapsedProps } from './SourceCollapsed';
import { SourceType } from 'types/enums';

const SourceCollapsedMedium: React.FC<SourceCollapsedProps & StyleProps> = ({
  source,
  preload,
  shouldOpenInNewTab,
  titleOverride,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const sourceUrl = useMemo(
    () => (source ? `/article/${source?.id}/${slugify(source?.title)}` : ''),
    [source?.id],
  );

  const router = useRouter();
  const isMobileView = useIsMobileView();

  if (!source) return null;

  return (
    <Flex
      width={isMobileView ? spacing.toRem(240) : spacing.toRem(200)}
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      flexShrink={0}
      cursor="pointer"
      onClick={() => {
        shouldOpenInNewTab ? window.open(sourceUrl) : router.push(sourceUrl);
      }}
      gridGap={spacing.toRem(24)}
      {...props}
    >
      <Flex position="relative">
        <SourceImageV2
          size={isMobileView ? spacing.toRem(240) : spacing.toRem(200)}
          source={source}
          preload={preload}
          hasShadow={false}
        />
        {source.ideaCount && (
          <IdeaCounter
            fontSize={'0.8rem'}
            bottom={spacing.toRem(4)}
            zIndex={2}
            left="50%"
            transform="translateX(-50%)"
            position="absolute"
            textColor={color[colorMode].text}
            underlineColor={getSourceThemeColor(source.sourceType, colorMode)}
            ideaCount={source.ideaCount}
          />
        )}
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="start"
        whiteSpace={'break-spaces'}
        color={color[colorMode].text}
      >
        <Link
          href={sourceUrl}
          _hover={undefined}
          target={shouldOpenInNewTab ? '_blank' : ''}
        >
          <PrimaryText
            noOfLines={2}
            textAlign="center"
            textDecoration="none"
            fontWeight={600}
            size="l"
            lineHeight="1.125"
            mb={spacing.toRem(8)}
          >
            {titleOverride ?? source.title}
          </PrimaryText>
        </Link>
        {source.sourceType !== SourceType.YOURSELF && (
          <PrimaryText textAlign="center" size="s" noOfLines={1} opacity={0.6}>
            {getSourceSubtitle(source)}
          </PrimaryText>
        )}
      </Flex>
    </Flex>
  );
};

export default SourceCollapsedMedium;
