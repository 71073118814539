import {
  Box,
  color,
  Flex,
  Link,
  PrimaryText,
  spacing,
  useColorMode,
} from 'deepstash-ui';
import { useDiffSinceTimestamp } from 'hooks/date/useDiffSinceTimestamp';
import React from 'react';
import { UserComment } from 'types/models';
import { onLinkClick } from 'utils/global';
import { completeDeepstashHtml } from 'utils/html-utils';
import NextLink from '../navigation/NextLink';
import ProfilePicture from '../social/ProfilePicture';

interface CommentUserEntryProps {
  comment: UserComment;
}

const CommentUserEntry: React.FC<CommentUserEntryProps> = ({ comment }) => {
  const { colorMode } = useColorMode();

  const { userData, textHtml, createdAt } = comment;

  const { timestamp } = useDiffSinceTimestamp(createdAt);

  const commentText = completeDeepstashHtml(textHtml, colorMode);

  const trimFirstName = (firstName: string) => {
    if (firstName.length <= 19) return firstName;
    return `${firstName.substring(0, 16)}...`;
  };

  return (
    <Box
      p={spacing.XS.rem}
      color={color[colorMode].text}
      width={{ base: spacing.toRem(300), lg: spacing.toRem(340) }}
    >
      <Flex>
        <NextLink href={`/u/${userData.username}`} passHref>
          <Link
            _hover={undefined}
            _active={undefined}
            _focus={undefined}
            onClick={onLinkClick}
          >
            <ProfilePicture
              photo={userData?.photo ?? ''}
              size="l"
              mr={spacing.XS.rem}
              cursor="pointer"
            />
          </Link>
        </NextLink>
        <Box
          px={spacing.M.rem}
          py={spacing.XS.rem}
          backgroundColor={color[colorMode].top}
          width="100%"
          borderRadius={spacing.M.rem}
          cursor="auto"
        >
          <Flex justifyContent="space-between" align="center">
            <PrimaryText type="bold" mb={spacing.XXS.rem} noOfLines={1}>
              {`${trimFirstName(userData.firstName)} ${userData.lastName}`}
            </PrimaryText>
            <PrimaryText
              size="xs"
              color={color[colorMode].textDisabled}
              ml={spacing.XS.rem}
              whiteSpace="nowrap"
            >
              {timestamp}
            </PrimaryText>
          </Flex>
          <PrimaryText wordBreak="break-word">{commentText}</PrimaryText>
        </Box>
      </Flex>
    </Box>
  );
};

export default CommentUserEntry;
